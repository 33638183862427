<div class="p-4">
    <div class="pb-4 flex space-x-4 items-center">
        <button class="items-center flex" (click)="backToReceiptHistoryLits()">
            <fa-icon [icon]="faChevronLeft" class="text-md"></fa-icon>
        </button>
        <div class="font-bold text-base lg:text-lg">
            รายละเอียดรับเข้า
        </div>
    </div>
    <!-- header mobile/ipad veiw -->
    <div class="block lg:hidden py-4 border-t space-y-4">
        <div class="space-y-1">
            <div class="flex justify-between">
                <div>เวลาเริ่มรับเข้า:</div>
                <div>12/10/2024, 12:12</div>
            </div>
            <div class="flex justify-between">
                <div>เวลารับเข้าเสร็จ:</div>
                <div>12/10/2024, 12:12</div>
            </div>
            <div class="flex justify-between">
                <div>รับเข้าโดย:</div>
                <div>ทดสอบ ทดสอบ</div>
            </div>
            <!-- <div class="flex justify-between">
                <div>จำนวนสินค้า:</div>
                <div class="font-bold text-blue-500 text-lg">20 ชิ้น</div>
            </div> -->
        </div>
    </div>
    <!-- header desktop veiw -->
    <div class="hidden lg:block py-6 border-t space-y-4">
        <div class="grid xl:grid-cols-2 2xl:grid-cols-4 gap-4">
            <div class="card">
                <div class="font-bold">เวลาเริ่มรับเข้า</div>
                <div>12/10/2024, 12:12</div>
            </div>
            <div class="card">
                <div class="font-bold">เวลารับเข้าเสร็จ</div>
                <div>12/10/2024, 12:12</div>
            </div>
            <div class="card">
                <div class="font-bold">รับเข้าโดย</div>
                <div>ทดสอบ ทดสอบ</div>
            </div>
            <div class="card">
                <div class="font-bold">จำนวนสินค้า</div>
                <div class="font-bold text-blue-500 text-lg">20 ชิ้น</div>
            </div>
        </div>
    </div>
    <!-- product mobile/ipad veiw -->
    <div class="block lg:hidden pt-4 border-t space-y-2">
        <div class="flex justify-between">
            <div class="flex justify-start space-x-4 pb-4">
                <div class="flex space-x-2 items-center">
                    <div class="font-bold">รายการสินค้า</div>
                    <div class="">(20 ชิ้น)</div>
                </div>
            </div>
        </div>
        @for (product of dataSource; track $index) {
        <div class="grid grid-cols-5 md:grid-cols-8 xl:grid-cols-12 gap-2 border p-2 bg-white rounded-lg">
            <div class="col-span-1">
                <div class="w-full lg:w-16 aspect-square">
                    <img [src]="'https://storage.remaxthailand.co.th/img.php?src=img/product/' + product.sku + '/1.jpg'"
                        [alt]="product.sku" class="rounded-sm"
                        onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                </div>
            </div>
            <div class="col-span-3 md:col-span-6 xl:col-span-10  h-[56px]">
                <div class="line-clamp-2 text-sm">{{ product.name }}</div>
            </div>
            <div class="col-span-1 text-right pr-1 flex justify-end h-fit">
                <div class="flex space-x-1 items-center">
                    <span class="text-sm text-secondary-400">x</span>
                    <span class="text-sm md:text-base text-secondary-700 font-bold">{{ product.serials.length }}</span>
                </div>
            </div>
        </div>
        }
    </div>
    <!--product in desktop veiw-->
    <div class="hidden lg:block">
        <div class="div-table !pr-6">
            <table>
                <thead>
                    <tr class="thead-tr">
                        <th class="text-left">
                            สินค้า
                        </th>
                        <th class="text-right">
                            จำนวนที่รับเข้า
                        </th>
                    </tr>
                </thead>
                <tbody>
                    @for (product of dataSource; track $index) {
                    <tr class="">
                        <td>
                            <div class="flex space-x-2">
                                <div class="w-full lg:w-16 aspect-square">
                                    <img [src]="'https://storage.remaxthailand.co.th/img.php?src=img/product/' + product.sku + '/1.jpg'"
                                        [alt]="product.sku" class="rounded-sm"
                                        onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                                </div>
                                <div>
                                    <div class="line-clamp-2 text-sm text-gray-600">{{ product.name }}</div>
                                    <div class="text-primary-500">{{ product.sku }}</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="text-right text-sm text-blue-500 font-medium">
                                {{ product.serials.length}}
                            </div>
                        </td>
                    </tr>
                    }
                </tbody>
            </table>
        </div>
    </div>
</div>