import { Injectable } from '@angular/core';
import { ReportApiService } from '../api/report-api.service';
import * as ExcelJS from 'exceljs';
import FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(
    private reportApiService: ReportApiService
  ) { }

  getSalesReport(shopId: string, filter: any) {
    return this.reportApiService.getSalesReport(shopId, filter).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  getRefundReport(shopId: string, filter: any) {
    return this.reportApiService.getRefundReport(shopId, filter).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  getPaymentReport(shopId: string, filter: any) {
    return this.reportApiService.getPaymentReport(shopId, filter).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  getBestSellerProductReport(shopId: string, filter: any) {
    return this.reportApiService.getBestSellerProductReport(shopId, filter).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  getOrderReport(shopId: string, filter: any) {
    return this.reportApiService.getOrderReport(shopId, filter).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data, count: response.count }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  getReturnReport(shopId: string, filter: any) {
    return this.reportApiService.getReturnReport(shopId, filter).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data, count: response.count }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  getDiscountReport(shopId: string, filter: any) {
    return this.reportApiService.getDiscountReport(shopId, filter).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  getOrderStatusReport(shopId: string, filter: any) {
    return this.reportApiService.getOrderStatusReport(shopId, filter).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  getNewCustomerReport(shopId: string, filter: any) {
    return this.reportApiService.getNewCustomerReport(shopId, filter).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  getPaymentConfig() {
    return this.reportApiService.getPaymentConfig().then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  getAllSerial(filter: any) {
    return this.reportApiService.getAllSerial(filter).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  getAllPayment(filter: any) {
    return this.reportApiService.getAllPayment(filter).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  getAllProduct(filter: any) {
    return this.reportApiService.getAllProduct(filter).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  getExportReportOrder(shop_id: string, filter: any) {
    return this.reportApiService.getExportReportOrder(shop_id, filter).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  getExportReportReturn(shop_id: string, filter: any) {
    return this.reportApiService.getExportReportReturn(shop_id, filter).then((response: any) => {
      if (response.success) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: response.message }
      }
    })
  }

  async generateOrderReport(orderData: any[], data: any[], fileName: string) {
    const workbook = new ExcelJS.Workbook();

    // Order
    const worksheetOrder = workbook.addWorksheet('Order');
    worksheetOrder.columns = [
      { header: 'สาขา', key: 'shop_name', width: 30 },
      { header: 'วันที่ขาย', key: 'date', width: 20 },
      { header: 'วิธีการชำระ', key: 'payment', width: 20 },
      { header: 'Payment Ref.', key: 'payment_ref', width: 20 },
      { header: 'หมายเหตุการชำระ', key: 'note', width: 15 },
      { header: 'เลขที่บิล', key: 'order_no', width: 30 },
      { header: 'ต้นทุนรวม', key: 'cost', width: 10 },
      { header: 'ราคาสินค้ารวม', key: 'price', width: 10 },
      { header: 'ส่วนลดรวม', key: 'discount', width: 10 },
      { header: 'สุทธิ', key: 'total_price', width: 10 },
      { header: 'พนักงาน', key: 'saler', width: 20 }
    ];

    orderData.forEach((row) => {
      worksheetOrder.addRow(row);
    });
    worksheetOrder.getColumn('G').font = { color: { argb: '00B0F0' } }
    worksheetOrder.getColumn('H').font = { color: { argb: '0055FF' } }
    worksheetOrder.getColumn('I').font = { color: { argb: 'FF0000' } }
    worksheetOrder.getColumn('J').eachCell({ includeEmpty: true }, (cell, rowNumber) => {
      if (rowNumber > 1 && rowNumber <= data.length + 1) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'C6EFCE' },
        };
        cell.font = { color: { argb: '006100' } }
      }
    });
    worksheetOrder.getRow(1).font = { bold: true, color: { argb: '000' } };


    // Product
    const worksheet = workbook.addWorksheet('Product');
    worksheet.columns = [
      { header: 'สาขา', key: 'shop_name', width: 30 },
      { header: 'วันที่ขาย', key: 'date', width: 20 },
      { header: 'วิธีการชำระ', key: 'payment', width: 20 },
      { header: 'Payment Ref.', key: 'payment_ref', width: 20 },
      { header: 'หมายเหตุการชำระ', key: 'note', width: 15 },
      { header: 'SKU', key: 'sku', width: 10 },
      { header: 'ชื่อสินค้า', key: 'name', width: 40 },
      { header: 'หมวดหมู่', key: 'category', width: 20 },
      { header: 'บาร์โค้ด', key: 'serial_no', width: 20 },
      { header: 'เลขที่บิล', key: 'order_no', width: 30 },
      { header: 'ต้นทุน', key: 'cost', width: 10 },
      { header: 'ราคาขาย', key: 'price', width: 10 },
      { header: 'จำนวน', key: 'qty', width: 10 },
      { header: 'ส่วนลดทั้งหมด(฿)', key: 'discount', width: 10 },
      { header: 'ส่วนลดทั่วไป(฿)', key: 'discount_normal', width: 10 },
      { header: 'ส่วนลดคะแนนสะสม(฿)', key: 'discount_point', width: 10 }
    ];

    data.forEach((row) => {
      worksheet.addRow(row);
    });
    worksheet.getColumn('K').font = { color: { argb: '0055FF' } }
    worksheet.getColumn('L').font = { color: { argb: '0055FF' } }
    worksheet.getColumn('M').font = { color: { argb: '0055FF' } }

    worksheet.getColumn('N').eachCell({ includeEmpty: true }, (cell, rowNumber) => {
      if (rowNumber > 1 && rowNumber <= data.length + 1) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'C6EFCE' },
        };
        cell.font = { color: { argb: '006100' } }
      }
    });

    worksheet.getColumn('O').eachCell({ includeEmpty: true }, (cell, rowNumber) => {
      if (rowNumber > 1 && rowNumber <= data.length + 1) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFEB9C' },
        };
        cell.font = { color: { argb: '9C5700' } }
      }
    });

    worksheet.getColumn('P').eachCell({ includeEmpty: true }, (cell, rowNumber) => {
      if (rowNumber > 1 && rowNumber <= data.length + 1) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFEB9C' },
        };
        cell.font = { color: { argb: '9C5700' } }
      }
    });

    worksheet.getRow(1).font = { bold: true, color: { argb: '000' } };

    // Generate the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    this.saveExcelFile(buffer, `${fileName}.xlsx`);
  }

  async generateReturnReport(data: any[], fileName: string) {
    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet('สินค้ารับคืน');
    worksheet.columns = [
      { header: 'สาขา', key: 'shop_name', width: 30 },
      { header: 'วันที่ขาย', key: 'date', width: 20 },
      { header: 'วิธีการคืนเงิน', key: 'payment', width: 20 },
      { header: 'หมายเหตุ', key: 'note', width: 15 },
      { header: 'SKU', key: 'sku', width: 10 },
      { header: 'ชื่อสินค้า', key: 'name', width: 40 },
      { header: 'หมวดหมู่', key: 'category', width: 20 },
      { header: 'บาร์โค้ด', key: 'serial_no', width: 20 },
      { header: 'เลขที่รับคืน', key: 'return_no', width: 30 },
      { header: 'ราคาขาย', key: 'price', width: 10 },
      { header: 'จำนวน', key: 'qty', width: 10 },
      { header: 'ส่วนลดจากบิลเดิม', key: 'discount', width: 10 },
      { header: 'สุทธิ', key: 'total_price', width: 10 }
    ];

    data.forEach((row) => {
      worksheet.addRow(row);
    });
    worksheet.getColumn('J').font = { color: { argb: '0055FF' } }
    worksheet.getColumn('K').font = { color: { argb: '0055FF' } }
    worksheet.getColumn('L').font = { color: { argb: '0055FF' } }

    worksheet.getColumn('M').eachCell({ includeEmpty: true }, (cell, rowNumber) => {
      if (rowNumber > 1 && rowNumber <= data.length + 1) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'C6EFCE' },
        };
        cell.font = { color: { argb: '006100' } }
      }
    });

    worksheet.getRow(1).font = { bold: true, color: { argb: '000' } };

    // Generate the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    this.saveExcelFile(buffer, `${fileName}.xlsx`);
  }

  private saveExcelFile(buffer: ArrayBuffer, filename: string) {
    const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    FileSaver.saveAs(data, filename);
  }

}
