<div class="p-4 pb-32 space-y-4">
    <div class="flex space-x-2 items-center">
        <div class="font-bold text-base lg:text-lg">ส่งเคลมสินค้า </div>
    </div>
    <div class="space-y-4">
        @if (isLoading) {
        <div class="card">
            <div class="flex justify-center py-16">
                <app-loader></app-loader>
            </div>
        </div>
        }@else {
        <div class="space-y-4 card">
            <div class="flex flex-col md:flex-row gap-4 my-4">
                <div class="flex flex-col md:flex-row justify-center items-center w-full md:w-fit">
                    <div class="mb-4 md:mb-0">
                        <img [src]="'https://storage.remaxthailand.co.th/img.php?src=img/product/'+ product.sku +'/1.jpg'"
                            class="w-48 h-48 rounded-lg border"
                            onerror="this.onerror=null; this.src='assets/images/warranty.png'" />
                    </div>
                </div>
                <div class="flex flex-col justify-between">
                    <div class="font-bold text-lg my-2">{{product.productName}}</div>
                    <div>
                        <div>Serial Number: {{product.barcode}}</div>
                        <div>จำหน่ายเมื่อ: {{dateFormat(product.sellDate)}}</div>
                        <div>วันหมดอายุการรับประกัน: {{dateFormat(product.expireDate)}}</div>
                    </div>
                </div>
            </div>
        </div>
        <form class="card space-y-10 divide-y" [formGroup]="claimForm" (ngSubmit)="onSubmit()">
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 pt-4 lg:px-4">
                <div class="font-bold">
                    รายละเอียด
                </div>
                <div>
                    <label>อาการชำรุด *</label>
                    <textarea id="description" type="text" class="text-area" formControlName="description"
                        required></textarea>
                    <mat-error *ngIf="isSubmit && claimForm.get('description')?.hasError('required')"
                        class="text-sm text-red-500">{{getErrorMessage('description')}}</mat-error>
                </div>
            </div>
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 pt-4 lg:px-4">
                <div class="font-bold">
                    ข้อมูลการติดต่อ
                </div>
                <div class="space-y-2">
                    <div>
                        <label>ชื่อ *</label>
                        <input type="text" class="input" formControlName="firstname" required />
                        <mat-error *ngIf="isSubmit && claimForm.get('firstname')?.hasError('required')"
                            class="text-sm text-red-500">{{getErrorMessage('firstname')}}</mat-error>
                    </div>
                    <div>
                        <label>นามสกุล *</label>
                        <input type="text" class="input" formControlName="lastname" />
                        <mat-error *ngIf="isSubmit && claimForm.get('lastname')?.hasError('required')"
                            class="text-sm text-red-500">{{getErrorMessage('lastname')}}</mat-error>
                    </div>
                    <div>
                        <label>ชื่อผู้ติดต่อ *</label>
                        <input type="text" class="input" formControlName="nickname" />
                        <mat-error *ngIf="isSubmit && claimForm.get('nickname')?.hasError('required')"
                            class="text-sm text-red-500">{{getErrorMessage('nickname')}}</mat-error>
                    </div>
                    <div>
                        <label>เบอร์โทรศัพท์ *</label>
                        <input type="tel" class="input" formControlName="tel" />
                        <mat-error *ngIf="isSubmit && claimForm.get('tel')?.hasError('required')"
                            class="text-sm text-red-500">{{getErrorMessage('tel')}}</mat-error>
                    </div>
                    <div>
                        <label>อีเมล</label>
                        <input type="email" class="input" formControlName="email" />
                    </div>
                    <div>
                        <label>Line ID</label>
                        <input type="text" class="input" formControlName="customerLineId" />
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 pt-4 lg:px-4">
                <div class="font-bold">
                    ที่อยู่ในการจัดส่ง
                </div>
                <div class="space-y-2">
                    <div class="pb-2">
                        <div class="rounded-lg bg-white grid grid-cols-2 divide-x border w-64">
                            <button type="button" class="text-center py-1.5 rounded-l-lg text-gray-600"
                                [ngClass]="{'shadow-inner bg-gray-200 !text-black font-medium' : state === 'shop'}"
                                (click)="changeState('shop')">
                                รับที่ร้าน
                            </button>
                            <button type="button" class="text-center py-1.5 rounded-r-lg text-gray-600"
                                [ngClass]="{'shadow-inner bg-gray-200 !text-black font-medium' : state === 'home'}"
                                (click)="changeState('home')">
                                ที่อยู่ลูกค้า
                            </button>
                        </div>
                    </div>

                    <div>
                        <label>ที่อยู่ *</label>
                        <input type="text" class="input" formControlName="address"
                            [readOnly]="state === 'shop'? true:false" />
                        <mat-error *ngIf="isSubmit && claimForm.get('address')?.hasError('required')"
                            class="text-sm text-red-500">{{getErrorMessage('address')}}</mat-error>
                    </div>
                    <div>
                        <label for="provinceSelection">จังหวัด *</label>
                        @if (state === 'shop') {
                        <input type="text" class="input" formControlName="province" readonly />
                        }@else {
                        <mat-form-field class="w-full">
                            <mat-select id="provinceSelection" formControlName="province"
                                (selectionChange)="onProvinceChange()">
                                @for (option of provinceSelections; track $index){
                                <mat-option [value]="option"> {{option}} </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                        <mat-error *ngIf="isSubmit && claimForm.get('province')?.hasError('required')"
                            class="text-sm text-red-500">{{getErrorMessage('province')}}</mat-error>
                        }
                    </div>
                    <div>
                        <label>เขต/อำเภอ *</label>
                        @if (state === 'shop') {
                        <input type="text" class="input" formControlName="district" readonly />
                        }@else {
                        <mat-form-field class="w-full">
                            <mat-select id="districtSelection" class="" formControlName="district"
                                (selectionChange)="onDistrictChange()">
                                @for (option of districtSelections; track $index){
                                <mat-option [value]="option"> {{option}} </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                        <mat-error *ngIf="isSubmit && claimForm.get('district')?.hasError('required')"
                            class="text-sm text-red-500">{{getErrorMessage('district')}}</mat-error>
                        }
                    </div>
                    <div>
                        <label>แขวง/ตำบล *</label>
                        @if (state === 'shop') {
                        <input type="text" class="input" formControlName="subDistrict" readonly />
                        }@else {
                        <mat-form-field class="w-full">
                            <mat-select id="subdistrictSelection" formControlName="subDistrict"
                                (selectionChange)="onSubdistrictChange()">
                                @for (option of subdistrictSelections; track $index){
                                <mat-option [value]="option"> {{option}} </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                        <mat-error *ngIf="isSubmit && claimForm.get('subDistrict')?.hasError('required')"
                            class="text-sm text-red-500">{{getErrorMessage('subDistrict')}}</mat-error>
                        }
                    </div>
                    <div>
                        <label>รหัสไปรษณีย์ *</label>
                        <input type="text" class="input" formControlName="zipcode"
                            [readOnly]="state === 'shop'? true:false" />
                        <mat-error *ngIf="isSubmit && claimForm.get('zipcode')?.hasError('required')"
                            class="text-sm text-red-500">{{getErrorMessage('zipcode')}}</mat-error>
                    </div>
                </div>
            </div>
            <div class="flex justify-end">
                <div class="grid grid-cols-2 gap-2 pt-4 w-full lg:w-1/2">
                    <button type="button" class="btn-secondary" (click)="onCancel()">ยกเลิก</button>
                    <button type="submit" class="btn-primary">บันทึก</button>
                </div>
            </div>
        </form>
        }
    </div>
</div>

@if (processing) {
<div class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-5 z-50 flex justify-center items-center">
    <span class="loader-processing !w-9 !h-9 !border-8 -mt-10"></span>
</div>
}