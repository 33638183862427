import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../../core/services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ReportApiService {

  private API_END_POINT = environment.endpoint;

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) { }

  getSalesReport(shopId: string, filters: any) {
    const endPoint = this.API_END_POINT;
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/report/sales/${shopId}?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getRefundReport(shopId: string, filters: any) {
    const endPoint = this.API_END_POINT;
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/report/refund/${shopId}?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getPaymentReport(shopId: string, filters: any) {
    const endPoint = this.API_END_POINT;
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/report/payment/${shopId}?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getBestSellerProductReport(shopId: string, filters: any) {
    const endPoint = this.API_END_POINT;
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/report/best-seller-product/${shopId}?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getOrderReport(shopId: string, filters: any) {
    const endPoint = this.API_END_POINT;
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/report/order/${shopId}?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getReturnReport(shopId: string, filters: any) {
    const endPoint = this.API_END_POINT;
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/report/return/${shopId}?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getDiscountReport(shopId: string, filters: any) {
    const endPoint = this.API_END_POINT;
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/report/discount/${shopId}?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getOrderStatusReport(shopId: string, filters: any) {
    const endPoint = this.API_END_POINT;
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/report/order-status/${shopId}?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getNewCustomerReport(shopId: string, filters: any) {
    const endPoint = this.API_END_POINT;
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/report/new-customer/${shopId}?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getPaymentConfig() {
    const endPoint = this.API_END_POINT;
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/shops/payment-config`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getAllSerial(filters: any) {
    const endPoint = this.API_END_POINT;
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/products/serial?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getAllPayment(filters: any) {
    const endPoint = this.API_END_POINT;
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/payment?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getAllProduct(filters: any) {
    const endPoint = this.API_END_POINT;
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/products/product/?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getExportReportOrder(shop_id: string, filters: any) {
    const endPoint = this.API_END_POINT;
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/report/export/order/${shop_id}?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getExportReportReturn(shop_id: string, filters: any) {
    const endPoint = this.API_END_POINT;
    const headers = this.apiService.getAPIHeader();
    const params = this.apiService.parseFilters(filters);
    return new Promise((resolve, reject) => {
      return this.http.get(`${endPoint}/report/export/return/${shop_id}?${params}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

}
