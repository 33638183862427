import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faArrowDown, faDownload, faFilter, faMagnifyingGlass, faPencil, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ProductApiService } from '../../services/api/product-api.service';
import { ProductsService } from '../../services/products/products.service';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { AuthGuardService } from '../../../../core/services/auth-guard/auth-guard.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrl: './product-list.component.css'
})
export class ProductListComponent {

  faPencil = faPencil;
  faFilter = faFilter;
  faDownload = faDownload;
  faScrewdriverWrench = faScrewdriverWrench;
  faMagnifyingGlass = faMagnifyingGlass;
  faArrowDown = faArrowDown;

  countDataSource = 0;
  filteredData: any = [];
  dataSource: any = [];
  isLoaded = true;
  loadingMore = false;

  incrementLimit = 10
  pageSize = 10;
  pageIndex = 0;
  limit = 10;
  offset = 0;

  isOpen = false;

  stockStatusOptions: { id: string, name: string }[] = [
    {
      "id": "",
      "name": ""
    },
    {
      "id": 'instock',
      "name": 'สินค้าพร้อมขาย'
    },
    {
      "id": 'lowstock',
      "name": 'สินค้าใกล้หมด'
    },
    {
      "id": 'outstock',
      "name": 'สินค้าหมด'
    }
  ]

  categoryOptions: { id: string, name: string }[] = [];
  brandOptions: { id: string, name: string }[] = [];

  priceConfigs: { price_config_id: string, name: string, active: boolean }[] = []
  productShops: any[] = []
  products: any[] = []

  shopId: string = this.authService.selectedShop?.shop_id || '';

  countFilter: number = 0;
  openFilterProduct = false;
  filters: UntypedFormGroup = new UntypedFormGroup({
    keyword: new UntypedFormControl(''), // product filter
    category_id: new UntypedFormControl(''), // product filter
    brand_id: new UntypedFormControl(''), // product filter
    stock_status: new UntypedFormControl('') // product shop filter
  });

  price: any;

  isEditing: any = [];

  constructor(
    private authService: AuthService,
    public dialog: MatDialog,
    public bottomSheet: MatBottomSheet,
    private productApi: ProductApiService,
    private productService: ProductsService,
    private authGaurdService: AuthGuardService
  ) {

  }

  async ngOnInit(): Promise<void> {
    const [products, brands, catgorirs] = await Promise.all([
      this.getProduct(),
      this.getBrand(),
      this.getCategory()
    ])
    this.brandOptions = this.formatBrand(brands);
    this.categoryOptions = this.formatCategory(catgorirs);
    this.priceConfigs = await this.getPriceConfig(this.shopId)
  }

  async filterChange() {
    this.offset = 0;
    this.pageIndex = 0;
    this.filteredData = [];

    this.countFilter = 0;
    Object.keys(this.filters.value).forEach(element => {
      if (this.filters.value[element]) this.countFilter++;
    });

    this.loadingMore = true
    await this.getProduct()
    this.loadingMore = false
  }

  formatCategory(categories: any[]) {
    return categories.map(category => ({
      id: category.category_id,
      name: category.name
    }))
  }

  formatBrand(brands: any[]) {
    return brands.map(brand => ({
      id: brand.brand_id,
      name: brand.name
    }))
  }

  async getCategory() {
    return await this.productApi.getAllCategory({}).then((response: any) => {
      return response.data || []
    })
  }

  async getBrand() {
    return await this.productApi.getAllBrand({}).then((response: any) => {
      return response.data || []
    })
  }

  async getPriceConfig(shopId: string) {
    return await this.productApi.getAllPriceConfigByShop(shopId, { active: true }).then((response: any) => {
      return response.data || []
    })
  }


  async getProduct() {
    this.isLoaded = false;
    const filters = this.filters.value
    // if have product filter
    if (filters.keyword.length > 0 || filters.brand_id.length > 0 || filters.category_id.length > 0) {
      let productFilters: any = {}
      if (filters.keyword.length > 0) { productFilters['keyword'] = filters.keyword }
      if (filters.brand_id.length > 0) { productFilters['brand_id'] = filters.brand_id }
      if (filters.category_id.length > 0) { productFilters['category_id'] = filters.category_id }

      console.log(productFilters)

      const filteredProduct: any = await this.productApi.getAllProduct(productFilters)
      this.products = filteredProduct?.data || []
      if (this.products.length > 0) {
        const sku: string[] = this.products.map((el: any) => el.sku);
        let productShopFilter: any = {}
        if (sku.length > 0) { productShopFilter['sku'] = sku.toString() }
        if (filters.stock_status.length > 0) { productShopFilter['stock_status'] = filters.stock_status }
        productShopFilter['limit'] = this.limit
        productShopFilter['offset'] = this.offset

        const productShopResponse: any = await this.productApi.getAllProductShop(this.shopId, productShopFilter)
        this.productShops = productShopResponse?.data || [];
        this.countDataSource = productShopResponse?.count || 0;
      } else {
        this.productShops = []
        this.countDataSource = 0
      }
      this.dataSource = this.formatData(this.productShops, this.products)
      this.filteredData.push(...this.formatData(this.productShops, this.products))
      console.log(this.dataSource)
      this.isLoaded = true;
    } else { // no have product filter
      let productShopFilter: any = {}
      if (filters.stock_status.length > 0) { productShopFilter['stock_status'] = filters.stock_status }
      productShopFilter['limit'] = this.limit
      productShopFilter['offset'] = this.offset

      const productShopResponse: any = await this.productApi.getAllProductShop(this.shopId, productShopFilter)
      this.productShops = productShopResponse?.data || [];
      this.countDataSource = productShopResponse?.count || 0;

      if (productShopResponse?.data?.length > 0) {
        const productReeposne: any = await this.productApi.getAllProduct({ sku: productShopResponse.data.map((el: any) => el.sku) })
        this.products = productReeposne?.data || []
      } else {
        this.products = []
      }
      this.dataSource = this.formatData(this.productShops, this.products)
      this.filteredData.push(...this.formatData(this.productShops, this.products))
      console.log(this.dataSource)
      this.isLoaded = true;
    }
  }

  getProductPrice(prices: { id: string, price: number }[], priceConfigId: string) {
    return prices.find(el => el.id === priceConfigId)?.price || 0
  }

  formatData(productShops: any[], products: any[]) {
    const formatData: { sku: string, name: string, stock: number, cost: number, min_stock: number, prices: { id: string, price: number }[] }[] = []
    for (const product of productShops) {
      const productDetail = products.find((el: any) => el.sku === product.sku);
      formatData.push({
        sku: product.sku,
        name: productDetail?.name || '',
        cost: product.cost,
        stock: product.summary,
        min_stock: product.min_stock,
        prices: product.prices
      })
    }
    return formatData
  }

  callBackPageChange = (data: any): void => {
    if (this.offset !== data.offset || this.limit !== data.limit) {
      this.offset = data.offset;
      this.limit = data.limit;
      this.getProduct()
    }

    if (data.pageIndex !== undefined) {
      this.pageIndex = data.pageIndex;
    }
    if (data.limit !== undefined) {
      this.pageSize = data.limit;
    }
  }

  async loadMore() {
    this.offset += this.incrementLimit;
    this.loadingMore = true
    await this.getProduct()
    this.loadingMore = false
  }

  callBackSubmit = (res: any) => {
    console.log(res)
    if (res.confirm) {
      this.openFilterProduct = false
      this.filters.patchValue(res.data)
      this.filterChange()
    } else {
      this.openFilterProduct = false
    }
  }

  openProductFilterDialog() {
    this.openFilterProduct = true;
  }

  toggleEditPrice(sku: string, priceConfigId: string) {
    this.price = null;
    this.isEditing[`${sku}${priceConfigId}`] = this.isEditing[`${sku}${priceConfigId}`] ? !this.isEditing[`${sku}${priceConfigId}`] : true;

    if (this.isEditing[`${sku}${priceConfigId}`]) {
      setTimeout(() => {
        document.getElementById('price-add')?.focus()
      }, 10)
    }

  }

  updatePrice(sku: string, priceConfigId: string) {
    setTimeout(() => {
      document.getElementById('price-add')?.blur()
    }, 10)

    console.log(this.price)
    if (this.price) {
      const body = {
        id: priceConfigId,
        price: this.price
      }
      console.log(this.shopId)
      console.log(sku)
      this.productService.updatePriceProductShop(this.shopId, sku, body).then((response: any) => {
        console.log(response)
        if (response.success) {
          const index = this.dataSource.findIndex((item: any) => item.sku === sku)
          if (index > -1) {
            this.dataSource[index].prices = response.data.prices
          }
        }
      })
    }
  }

  canEditPrice() {
    return this.authGaurdService.canAction(['manager', 'admin'])
  }

  canAccessSettingPrice() {
    return this.authGaurdService.canAction(['manager', 'admin'])
  }

  async downloadProduct() {
    const productShopFilter = {
      fields: 'sku,summary,prices,cost'
    }
    const productShopResponse: any = await this.productApi.getAllProductShop(this.shopId, productShopFilter)

    const skus = [...new Set(productShopResponse.data.map((el: any) => el.sku))]
    const chunks = this.chunkArray(skus, 150);
    const results: any[] = await Promise.all(
      chunks.map(async (sku) => {
        const reqFilters = {
          sku: sku,
          fields: 'sku,name,category'
        }
        return await this.productApi.getAllProduct(reqFilters);
      })
    );
    const combinedData = results.reduce((acc, result) => {
      if (result?.data) {
        acc.push(...result.data);
      }
      return acc;
    }, []);
    const productResposne = combinedData
    const product = productResposne.reduce((a: any, v: any) => ({ ...a, [v.sku]: v }), {})

    // const productFilter = {
    //   sku: productShopResponse.data.map((el: any) => el.sku),
    //   fields: 'sku,name,category'
    // }
    // const productResposne: any = await this.productApi.getAllProduct(productFilter)
    // const product = productResposne.data.reduce((a: any, v: any) => ({ ...a, [v.sku]: v }), {})

    const dataExport = [];

    for (const item of productShopResponse.data) {
      dataExport.push({
        sku: item.sku,
        name: product[item.sku]?.name,
        qty: item?.summary || 0,
        cost: item?.cost || 0,
        category: product[item.sku]?.category?.name,
        prices: this.priceConfigs.map(e => ({ name: e.name, price: item.prices?.find((x: any) => x.id === e.price_config_id)?.price | 0 }))
      })
    }
    console.log(dataExport);
    this.productService.generateProductExcel(dataExport, 'รายการสินค้า')

  }

  chunkArray<T>(array: T[], chunkSize: number): T[][] {
    const chunks: T[][] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  }

}
