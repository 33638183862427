<div class="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-30" *ngIf="open" (click)="close()">
</div>
<div class="component-slide-right"
    [ngClass]="{'before-position-slide-right': open, 'after-position-slide-right' : !open}">
    <div class="relative w-full h-svh"
        [ngClass]="{'transform transition-all md:opacity-100 duration-700 delay-300': open, 'transform transition-all md:opacity-0 duration-300 delay-75' : !open}">
        <div class="absolute top-0 flex gap-6 px-4 bg-white h-12 w-full shadow-sm items-center cursor-pointer"
            (click)="close()">
            <button class="">
                <fa-icon [icon]="faChevronLeft" class="text-xl text-gray-600"></fa-icon>
            </button>
            <div class="text-lg font-bold whitespace-nowrap">
                ประวัติการขายสินค้า
            </div>
        </div>
        <div class="flex flex-col justify-between p-4 pt-16 h-full overflow-y-auto">
            @if(detail){
            <div class="space-y-5">
                <div class="flex flex-col gap-4 my-4">
                    <div class="flex flex-col justify-center items-center w-full">
                        <div class="">
                            <img [src]="'https://storage.remaxthailand.co.th/img.php?src=img/product/'+ detail.sku +'/1.jpg'"
                                class="w-48 h-48 rounded-lg border"
                                onerror="this.onerror=null; this.src='assets/images/warranty.png'" />
                        </div>
                    </div>
                </div>
                <div>
                    <div class="text-gray-500">
                        สินค้า
                    </div>
                    <div>
                        {{detail.name? detail.name : 'ไม่พบข้อมูลสินค้า'}}
                    </div>
                </div>
                <div>
                    <div class="text-gray-500">
                        Serial Number
                    </div>
                    <div>
                        {{detail.serial_no}}
                    </div>
                </div>
                <div>
                    <div class="text-gray-500">
                        ประวัติการขาย
                    </div>
                    <div>
                        @if (!detail.transaction || detail.transaction.length === 0) {
                        -ไม่มีประวัติการขาย-
                        }
                        @for (transaction of detail.transaction; track $index) {
                        <div class="py-2 border-b">
                            <div>
                                <span class="text-gray-500 pr-2">วันที่:</span> {{transaction.date}}
                            </div>
                            <div>
                                <span class="text-gray-500 pr-2">ร้านค้า:</span> {{transaction.shop}}
                            </div>
                            <div>
                                <span class="text-gray-500 pr-2">คำสั่งซื้อ:</span> {{transaction.orderNo}}
                            </div>
                            <div>
                                <span class="text-gray-500 pr-2">ลูกค้า:</span> {{transaction.customer || '-'}}
                            </div>
                            <div>
                                <span class="text-gray-500 pr-2">รายละเอียด:</span> {{transaction.detail}}
                            </div>
                        </div>
                        }
                    </div>
                </div>
                <div class="h-20"></div>
            </div>
            }
        </div>
        <div class="absolute bottom-0 flex px-4 bg-white pt-2 pb-4 w-full shadow-sm justify-center items-center">
            <button class="btn-primary w-full" (click)="confirmReceipt()" [disabled]="!detail?.sku">สร้างใบรับเข้า</button>
        </div>
    </div>
</div>