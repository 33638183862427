@if(!isLoading) {
<div class="p-4 pb-32">
    <div class="divide-y bg-white rounded-lg border px-4">
        <div class="flex items-center w-full space-x-4 px-2">
            <img class="w-14" src="assets/images/warehouse-out.png">
            <div class="py-4">
                <div class="font-bold text-lg">
                    #{{stockOutNo}}
                </div>
                <div class="text-sm text-gray-600">
                    หมายเลขการคืนสินค้า
                </div>
            </div>
        </div>
        <div class="grid grid-cols-3 w-full divide-x py-4">
            <div class="text-center w-full pr-2">
                <div class="font-bold">
                    {{displayDate(stockOutDetail.stock_out_date)}}
                </div>
                <div class="text-sm text-gray-600">
                    วันที่คืนสินค้า
                </div>
            </div>
            <div class="text-center w-full px-2">
                <div class="font-bold">
                    {{displayTime(stockOutDetail.stock_out_date)}}
                </div>
                <div class="text-sm text-gray-600">
                    เวลาคืนสินค้า
                </div>
            </div>
            <div class="flex flex-col justify-center items-center w-full pl-2 text-center">
                <div class="flex gap-x-2 flex-wrap items-center justify-center">
                    <div class="text-sm" [ngClass]="displayStatusColor(stockOutDetail.status)">
                        {{displayStatusOrder(stockOutDetail.status)}}
                    </div>
                    @if (stockOutDetail.payment) {
                    <div class="text-xs md:text-sm text-gray-600">
                        (ยอดชำระ ฿{{stockOutDetail.payment.amount}})
                    </div>
                    }
                </div>
            </div>
        </div>
    </div>
    <div class="py-4 space-y-3">
        <div class="flex justify-between items-center">
            <div class="font-bold">
                รายการสินค้าคืนสำนักงาน
            </div>
            <div class="">
                @if (canAccessSerial()) {
                <button class="flex space-x-2 items-center underline underline-offset-2 text-green-600"
                    (click)="downloadSerial()">
                    <div>
                        <fa-icon [icon]="faFileExcel" class=""></fa-icon>
                    </div>
                    <div class="text-sm">
                        Export Serial
                    </div>
                </button>
                }
            </div>
        </div>


        <div class="lg:hidden grid gap-2">
            @if(!items || items.length === 0){
            <div class="text-center py-10">
                <div class="flex flex-col justify-center items-center w-full">
                    <img src="../../../assets/images/blocked.png" class="h-20 aspect-auto">
                    <div class="text-center pt-4">ไม่พบรายการสินค้า</div>
                </div>
            </div>
            }
            @else {
            @for (item of formatProductDisplay(); track $index) {
            <button class="border bg-white rounded-lg divide-y text-sm" (click)="openDialogShowBarcode(item.sku)">
                <div class="p-3 grid grid-cols-6 md:grid-cols-9 gap-2 text-left">
                    <div class="col-span-1">
                        <img [src]="'https://storage.remaxthailand.co.th/img.php?src=img/product/' + item.sku + '/1.jpg'"
                            [alt]="item.sku" class="rounded-sm"
                            onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                    </div>
                    <div class="col-span-4 md:col-span-7">
                        <div class="flex flex-col gap-1">
                            <div class="">{{item.name}}</div>
                            <div class="text-yellow-500">{{item.sku}}</div>
                        </div>
                    </div>
                    <div class="col-span-1">
                        <div class="text-end">x {{item.qty}}</div>
                    </div>
                </div>
                <!-- <div class="px-3 py-2 flex justify-between items-center text-gray-500">
                    <div class="w-32 flex items-center">
                    </div>
                    <div class="bg-gray-100 text-gray-500 rounded-md flex px-3 py-1 w-fit">
                        <span>x {{item.qty}}</span>
                    </div>
                </div> -->
            </button>
            }
            }
        </div>

        <div class="hidden lg:grid grid-cols-1 gap-2">
            <div>
                <div class="div-table">
                    <table>
                        <thead>
                            <tr class="thead-tr">
                                <th class="text-left">
                                    สินค้า
                                </th>
                                <th class="text-right">
                                    จำนวน
                                </th>
                                @if (canAccessSerial()) {
                                <th class="text-center">
                                    Serial Number
                                </th>
                                }
                            </tr>
                        </thead>
                        @if(!items || items.length === 0){
                        <tbody>
                            <tr class="tbody-tr">
                                <td colspan="100" class="text-center py-10">
                                    <div class="flex flex-col justify-center items-center w-full">
                                        <img src="../../../assets/images/blocked.png" class="h-20 aspect-auto">
                                        <div class="text-center pt-4">ไม่พบรายการสินค้า</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        }
                        @else {
                        <tbody>
                            @for (item of formatProductDisplay(); track $index) {
                            <tr class="tbody-tr">
                                <td>
                                    <div class="flex gap-2">
                                        <div class="bg-white w-10 h-10 rounded-sm">
                                            <img [src]="'https://storage.remaxthailand.co.th/img.php?src=img/product/' + item.sku + '/1.jpg'"
                                                [alt]="item.sku" class="rounded-sm"
                                                onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                                        </div>
                                        <div class="">
                                            <div class="">{{item.name}}</div>
                                            <div class="text-yellow-500">{{item.sku}}</div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-right">
                                    {{item.qty}}
                                </td>
                                @if (canAccessSerial()) {
                                <td class="text-center">
                                    <button class="underline underline-offset-2"
                                        (click)="openDialogShowBarcode(item.sku)">ดูเพิ่มเติม</button>
                                </td>
                                }
                            </tr>
                            }
                        </tbody>
                        }
                    </table>
                </div>
            </div>
        </div>

    </div>
    <div class="flex justify-end">
        @if (stockOutDetail.status === 'ongoing' || stockOutDetail.status === 'waiting_confirm') {
        <div class="w-full md:w-2/4 xl:w-1/3 py-4 border-dashed border-t border-gray-300">
            <div class="mt-8">
                <button class="btn-light text-red-500 w-full" [disabled]="!canAccess()"
                    (click)="cancelStockOut()">ยกเลิกคืนสินค้า</button>
            </div>
        </div>
        }
    </div>
</div>
@if (stockOutDetail.status === 'ongoing') {
<div
    class="w-full md:w-[calc(100%-8rem)] fixed bottom-0 bg-white border-t border-x h-fit p-4 rounded-t-2xl shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] md:shadow-none">
    <button class="btn-primary w-full" routerLink="/stock-out/stock-out-create"
        [queryParams]="{stock_out_no: stockOutNo}">
        ดำเนินการต่อ
    </button>
</div>
}
@else if (stockOutDetail.status === 'waiting_confirm') {
<div
    class="w-full md:w-[calc(100%-8rem)] fixed bottom-0 bg-white border-t border-x h-fit p-4 rounded-t-2xl shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] md:shadow-none">
    <button class="btn-primary w-full" [disabled]="!canAccess()" (click)="confirmStockOut()">
        ยืนยันคืนสินค้า
    </button>
</div>
}
}@else {
<div class="flex justify-center pt-40">
    <app-loader></app-loader>
</div>
}

<div>
    <app-serial-out-dailog [open]="showSerial" [serials]="serials"
        (callBackClose)="callBackClose($event)"></app-serial-out-dailog>
</div>