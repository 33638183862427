<div class="p-4 pb-32">
    <!-- header desktop veiw -->
    <div class="hidden lg:block card mb-4">
        <div class="flex">
            <span class="font-bold lg:text-lg ">เลขที่สั่งซื้อ</span>
            <span class="hidden lg:block font-bold lg:text-lg text-red-500 ml-2">#{{orderNo}}</span>
        </div>
    </div>
    <!-- header mobile/ipad veiw -->
    <div class="block lg:hidden py-4 mb-4 border-y">
        <div class="space-y-1">
            <div class="flex justify-between">
                <div>เลขที่สั่งซื้อ:</div>
                <div class="font-bold">#{{orderNo}}</div>
            </div>
            <div class="flex justify-between">
                <div>วันที่สั่งซื้อ:</div>
                <div>{{receiptDetails.orderDate}}</div>
            </div>
            <div class="flex justify-between">
                <div>วันที่จัดส่ง:</div>
                <div>{{receiptDetails.shippedDate}}</div>
            </div>
        </div>
    </div>
    <!--product in mobile/ipad veiw-->
    <div class="block lg:hidden pb-4">
        <div class="flex justify-between">
            <div class="flex justify-start space-x-4 pb-4">
                <div class="flex space-x-2 items-center">
                    <div class="font-bold">สินค้า</div>
                    <div class="">({{totalOrderedItem | number}} ชิ้น)</div>
                </div>
            </div>
        </div>
        <div class="space-y-2 pb-4 mb-4 border-b">
            @for (product of receiptDetails.products; track $index) {
            <div class="grid grid-cols-6 md:grid-cols-8 xl:grid-cols-12 gap-2 border p-2 bg-white rounded-lg">
                <div class="col-span-1">
                    <div class="w-full lg:w-16 aspect-square">
                        <img [src]="'https://storage.remaxthailand.co.th/img.php?src=img/product/' + product.sku + '/1.jpg'"
                            [alt]="product.sku" class="rounded-sm"
                            onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                    </div>
                </div>
                <div class="col-span-3 md:col-span-5 xl:col-span-9 h-[56px]">
                    <div class="line-clamp-3 text-sm text-gray-600">{{ product.name }}</div>
                </div>
                <div class="col-span-2 text-right pr-1">
                    <div class="font-medium text-sm md:text-base">
                        x {{ product.ordered_serials.length | number}}
                    </div>
                    <div class="font-medium text-sm md:text-base">
                        {{ product.price | number }}฿
                    </div>
                    <div class="space-x-1">
                        @if (product.receipted_serials.length === product.ordered_serials.length) {
                        <span class="text-lime-500 font-medium text-sm md:text-base">รับครบแล้ว</span>
                        } @else {
                        <span class="text-sm md:text-base text-gray-400">ค้างรับ:</span>
                        <span
                            class="text-red-500 font-medium text-sm md:text-base">{{(product.ordered_serials.length-product.receipted_serials.length)
                            | number}}</span>
                        }
                    </div>
                </div>
            </div>
            }
        </div>
        <div class="pb-4 flex justify-center">
            <div class="w-full divide-y divide-dashed space-y-2">
                <div>
                    <div class="flex justify-between">
                        <div class="">ยอดรวมสินค้า</div>
                        <div class="font-medium">฿{{receiptDetails.summaryPrice | number}}</div>
                    </div>
                    <div class="flex justify-between">
                        <div class="">ค่าจัดส่ง</div>
                        <div class="font-medium">฿{{receiptDetails.shippingPrice | number}}</div>
                    </div>
                    <div class="flex justify-between">
                        <div class="">ส่วนลด</div>
                        <div class="font-medium">฿{{receiptDetails.discount | number}}</div>
                    </div>
                    <div class="flex justify-between">
                        <div class="">VAT</div>
                        <div class="font-medium">฿{{receiptDetails.vat | number}}</div>
                    </div>
                </div>
                <div class="flex justify-between pt-2">
                    <div class="font-bold">ยอดสุทธิ</div>
                    <div class="font-bold">฿{{receiptDetails.total | number}}</div>
                </div>
            </div>
        </div>
    </div>
    <!--DESKTOP VEIW-->
    <div class="hidden lg:grid grid-cols-3 gap-4">
        <div class="col-span-2 grid gap-4">
            <div class="card divide-y">
                <table>
                    <thead>
                        <tr>
                            <th class="text-left">
                                สินค้า
                            </th>
                            <th class="text-right">
                                จำนวนที่สั่ง
                            </th>
                            <th class="text-right">
                                ราคา/หน่วย
                            </th>
                            <th class="text-right">
                                รับเข้าแล้ว/ทั้งหมด
                            </th>
                        </tr>
                    </thead>
                    <tbody class="">
                        @for (product of receiptDetails.products; track $index) {
                        <tr>
                            <td>
                                <div class="flex w-full space-x-2">
                                    <div class="">
                                        <div class="w-full lg:w-16 aspect-square">
                                            <img [src]="'https://storage.remaxthailand.co.th/img.php?src=img/product/' + product.sku + '/1.jpg'"
                                                [alt]="product.sku" class="rounded-sm"
                                                onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                                        </div>
                                    </div>
                                    <div>
                                        <div class="line-clamp-2 text-sm text-gray-600">{{ product.name }}</div>
                                        <div class="text-primary-500">{{ product.sku }}</div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="text-right text-sm font-medium">
                                    x{{ product.ordered_serials.length}}
                                </div>
                            </td>
                            <td>
                                <div class="text-right text-sm font-medium">
                                    {{ product.price | number }}฿
                                </div>
                            </td>
                            <td class="relative">
                                <div class="text-right text-sm font-medium">
                                    {{product.receipted_serials.length}}/{{ product.ordered_serials.length}}
                                </div>
                                <div class="!absolute bottom-5 right-0 w-full xl:pl-6 2xl:pl-10">
                                    <mat-progress-bar mode="determinate"
                                        [ngClass]="{'progress-success': calcuatePercent(product.receipted_serials.length,product.ordered_serials.length) >= 100}"
                                        [value]="calcuatePercent(product.receipted_serials.length,product.ordered_serials.length)"></mat-progress-bar>
                                </div>
                            </td>
                        </tr>
                        }
                    </tbody>
                </table>
                <div class="flex justify-end pt-4">
                    <div class="w-1/2 2xl:w-2/5 divide-y divide-dashed space-y-2">
                        <div>
                            <div class="flex justify-between">
                                <div class="">ยอดรวมสินค้า</div>
                                <div class="font-medium">฿{{receiptDetails.summaryPrice | number}}</div>
                            </div>
                            <div class="flex justify-between">
                                <div class="">ค่าจัดส่ง</div>
                                <div class="font-medium">฿{{receiptDetails.shippingPrice | number}}</div>
                            </div>
                            <div class="flex justify-between">
                                <div class="">ส่วนลด</div>
                                <div class="font-medium">฿{{receiptDetails.discount | number}}</div>
                            </div>
                            <div class="flex justify-between">
                                <div class="">VAT</div>
                                <div class="font-medium">฿{{receiptDetails.vat | number}}</div>
                            </div>
                        </div>
                        <div class="flex justify-between pt-2">
                            <div class="font-bold">ยอดสุทธิ</div>
                            <div class="font-bold">฿{{receiptDetails.total | number}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-span-1 grid h-fit gap-4">
            <div class="card">
                <div class="border-b pb-4">
                    <span class="text-lg font-bold">สถานะ</span>
                </div>
                <div class="pt-4">
                    <div class="flex space-x-4">
                        <div class="w-12 h-12 bg-blue-200 rounded-full flex justify-center items-center">
                            <fa-icon [icon]="faFileText" class="text-lg text-blue-600"></fa-icon>
                        </div>
                        <div>
                            <div>วันที่สั่งซื้อ</div>
                            <div class="text-sm">{{receiptDetails.orderDate}}</div>
                        </div>
                    </div>
                    <div class="w-12">
                        <div class="w-full flex justify-center">
                            <div class="h-8 w-1 0"
                                [ngClass]="{'bg-blue-200': receiptDetails.shippedDate,'bg-gray-200': !receiptDetails.shippedDate}">
                            </div>
                        </div>
                    </div>
                    <div class="flex space-x-4">
                        <div class="w-12 h-12 rounded-full flex justify-center items-center"
                            [ngClass]="{'bg-blue-200': receiptDetails.shippedDate,'bg-gray-200': !receiptDetails.shippedDate}">
                            <fa-icon [icon]="faTruck" class="text-lg"
                                [ngClass]="{'text-blue-600': receiptDetails.shippedDate,'text-gray-600': !receiptDetails.shippedDate}"></fa-icon>
                        </div>
                        <div>
                            <div>วันที่จัดส่ง</div>
                            <div class="text-sm">{{receiptDetails.shippedDate}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="border-b pb-4">
                    <span class="text-lg font-bold">รับเข้าสินค้า</span>
                </div>
                <div class="space-y-2 pt-4">
                    <div class="flex justify-between">
                        <div>
                            <span class="pr-2">รับเข้าแล้ว</span>
                            <span class="text-blue-500 font-medium">
                                {{totalReceiptedItem |number}}/{{totalOrderedItem|number}}
                            </span>
                        </div>
                        <div class="font-bold"
                            [ngClass]="{'text-lime-500 ':calcuatePercent(this.totalReceiptedItem,this.totalOrderedItem) >= 100,'text-red-500 ':calcuatePercent(this.totalReceiptedItem,this.totalOrderedItem) < 100}">
                            {{calcuatePercent(this.totalReceiptedItem,this.totalOrderedItem)}}%</div>
                    </div>
                    <mat-progress-bar mode="determinate"
                        [ngClass]="{'progress-success': calcuatePercent(this.totalReceiptedItem,this.totalOrderedItem) >= 100}"
                        [value]="calcuatePercent(this.totalReceiptedItem,this.totalOrderedItem)"></mat-progress-bar>
                </div>
                @if (this.totalReceiptedItem - this.totalOrderedItem < 0) { <div
                    class="hidden lg:flex justify-start space-x-4 pt-6">
                    <button class="btn-secondary w-1/2" (click)="goToReceiptScan()">
                        สแกนรับเข้า
                    </button>
                    <button class="btn-primary w-1/2" (click)="confirmReceiptAll()">
                        รับเข้าทั้งหมด
                    </button>
            </div>
            }
        </div>
    </div>
</div>
</div>
<!-- footer mobile/ipad veiw -->
<div class="relative lg:hidden">
    @if (totalReceiptedItem - totalOrderedItem < 0) { <div
        class="w-full md:w-[calc(100%-8rem)] fixed bottom-0 bg-white border-t border-x h-28 p-4 rounded-t-2xl shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] md:shadow-none">
        <div class="flex justify-between">
            <div class="font-bold">
                ค้างรับทั้งหมด
            </div>
            <div class="font-bold">
                <span class="text-red-500 text-xl md:text-2xl pr-1">
                    {{ totalOrderedItem-totalReceiptedItem |number }}</span> ชิ้น
            </div>
        </div>
        <div class="grid grid-cols-2 gap-2 pt-2">
            <button class="btn-secondary" (click)="goToReceiptScan()">
                สแกนรับเข้า
            </button>
            <button class="btn-primary" (click)="confirmReceiptAll()">
                รับเข้าทั้งหมด
            </button>
        </div>
</div>
}
</div>