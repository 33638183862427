import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faArrowsRotate, faBarcode, faChevronDown, faTrash, faCartPlus } from '@fortawesome/free-solid-svg-icons';
import { SaleApiService } from '../../services/api/sale-api.service';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../../core/services/util/util.service';
import { QrPaymentComponent } from '../../components/qr-payment/qr-payment.component';
import { CashPaymentComponent } from '../../components/cash-payment/cash-payment.component';
import { SalesService } from '../../services/sales/sales.service';
import { CounterPaymentComponent } from '../../components/counter-payment/counter-payment.component';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { CreditPaymentComponent } from '../../components/credit-payment/credit-payment.component';
import { QrPromtpayPaymentComponent } from '../../components/qr-promtpay-payment/qr-promtpay-payment.component';
import { ShopService } from '../../../../core/services/shop/shop.service';
@Component({
  selector: 'app-sale-create',
  templateUrl: './sale-create.component.html',
  styleUrl: './sale-create.component.css'
})
export class SaleCreateComponent implements OnInit {

  faBarcode = faBarcode;
  faTrash = faTrash;
  faArrowsRotate = faArrowsRotate;
  faChevronDown = faChevronDown;
  faCartPlus = faCartPlus;

  orderPriceConfig = '';

  users = this.shopService.shopUsers;
  shopId: string = this.authService.selectedShop?.shop_id || '';
  shopName: string = this.authService.selectedShop?.name || '';

  priceSelect: any = '';
  priceSlots: any[] = [
    {
      key: 'oYzs7',
      name: 'ราคาปลีก'
    }
  ]

  mode: string = 'add'; // add, delete
  items: any[] = []

  orderNo: string = '';
  order: any = {};
  shopDetail: any = {};

  addFriend = false;

  member: any = {
    member: {
      userId: '',
      name: '',
      point: 0,
      orders: 0
    },
    phone_number: ''
  };

  confirmMember = false;

  addDiscount = false;
  selectDiscount: any = {};

  paymentOption = false
  paymentList: { id: string, img: string, name: string }[] = [];
  promptpay: { number: string, name: string } = { name: '', number: '' };

  serialNoDelete: any;
  serialNoAdd: any;

  isMember: boolean = false;
  memberData: any = null;

  addRedeemDiscount = false

  isCreating: boolean = false;
  canPrint: boolean = false;

  processing: boolean = false;


  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private saleApiService: SaleApiService,
    private modalService: ModalService,
    private router: Router,
    private utilService: UtilService,
    private salesService: SalesService,
    private shopService: ShopService,

  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(async params => {
      this.orderNo = params.get('order_no') || '';
      if (this.orderNo) {
        this.salesService.getOneOrder(this.orderNo).then((res: any) => {
          console.log(res)
          if (res.success && res.data) {
            this.items = res.data.items
            this.order = res.data;
            this.calculateOrder(this.items)
            this.getMemberData();
          }
        })
      }
    });
    Promise.all([this.getPriceConfig(), this.getShopInfo(), this.getShop()]).then(() => {
      this.getPaymentConfig();
    })
    setTimeout(() => {
      document.getElementById('barcode-add')?.focus()
    }, 10)
  }

  async getOrder() {
    await this.saleApiService.getOneOrder(this.orderNo).then((res: any) => {
      if (res.success && res.data) {
        this.items = res.data.items
        this.order = res.data;
        this.calculateOrder(this.items)
      }
    })
  }

  async getPaymentConfig() {
    await this.saleApiService.getShopByShopId(this.shopId).then((res: any) => {
      if (res.success) {
        this.paymentList = res.data.payment_method.map((e: any) => {
          const payment: any = this.salesService.paymentConfig.find((x: any) => e.id === x.id && x.type === 'sale')
          return { id: payment.id, name: payment.name, img: payment.img }
        })
        this.promptpay = res.data.promptpay;
      }
      this.paymentList.push({ id: 'waiting_payment', img: 'https://storage.maxxgadget.com/pos/config/payment/waiting.png', name: 'รอชำระเงิน' })
    })
  }

  async getShopInfo() {
    if (this.salesService.paymentConfig.length === 0) {
      await this.saleApiService.getShopPaymentConfig().then((res: any) => {
        if (res.success) {
          this.salesService.paymentConfig = res.data;
        }
      })
    }
  }

  async getShop() {
    const shop: any = await this.shopService.getShopByShopId(this.shopId)
    if (shop.data) {
      this.shopDetail = shop.data
    }
  }

  formatProductDisplay(): any[] {
    let result: any[] = [];
    let result2: any[] = [];
    for (const item of this.items.filter(e => e.is_return)) {
      let index = result.findIndex((itemDetail: any) => itemDetail.sku === item.sku);
      if (index === -1) { // new row
        result.push({
          ...item,
          qty: 1,
          is_return: true
        });
      } else {
        result[index].qty += 1;
      }
    }
    for (const item of this.items.filter(e => !e.is_return)) {
      let index = result2.findIndex((itemDetail: any) => itemDetail.sku === item.sku);
      if (index === -1) { // new row
        result2.push({
          ...item,
          qty: 1
        });
      } else {
        result2[index].qty += 1;
      }
    }
    return [...result, ...result2];
  }

  modeToggle(value: string) {
    this.mode = value;

    if (this.mode === 'add') {
      setTimeout(() => {
        document.getElementById('barcode-add')?.focus()
      }, 10)
    } else {
      setTimeout(() => {
        document.getElementById('barcode-delete')?.focus()
      }, 10)
    }
  }

  openAddfriendDialog = () => {
    if (this?.order?.order_no) {
      this.addFriend = true;
    } else {
      this.modalService.openModal('info', 'กรุณาเพิ่มสินค้าก่อนเพิ่มสมาชิก', '')
    }
  }

  openAddDiscountDialog = () => {
    if (this.order.ref) {
      this.modalService.openModal('fail', 'ขออภัย', 'การเปลี่ยนสินค้าไม่สามารถใช้ส่วนลดได้')
      return;
    }

    if (this.order?.items?.length > 0) {
      this.selectDiscount = {};
      const tmp = this.order.discounts.find((e: any) => e.type === 'promotion' || e.type === 'discount');
      console.log(tmp)
      if (tmp) {
        this.selectDiscount = {
          value: `${tmp.value}`,
          note: tmp.note,
          unit: tmp.unit,
          ref: tmp.ref,
          type: tmp.type
        }
      }
      this.addDiscount = true
    } else {
      this.modalService.openModal('info', 'กรุณาเพิ่มสินค้าก่อนใช้ส่วนลด', '')
    }
  }

  openPaymentDialog = () => {
    this.paymentOption = true
  }

  confirmOrder = async () => {
    console.log('Confirm!');
    if (this.order.total_price < 0 && this.order.items.find((e: any) => e.is_return)) {
      this.modalService.openModal('fail', 'ไม่สามารถเปลี่ยนสินค้าได้', 'กรุณาเพิ่มสินค้าให้ถึงมูลค่าขั้นต่ำของรายการเดิม')
      return;
    } else if (this.order.total_price < 0) {
      this.modalService.openModal('fail', 'ไม่สามารถดำเนินการต่อได้', 'ราคาสินค้าต่ำกว่า 0 บาท')
      return;
    }

    if (this.confirmMember) {
      this.openPaymentDialog();
    } else {
      this.openAddfriendDialog();
    }
  }

  closeDialog = () => {
    this.dialog.closeAll()
  }

  callBackAddFriend = async (data: any) => {
    this.confirmMember = data.confirm;
    console.log(data)
    if (data.confirm && data.isScan) {
      this.addFriend = false
      await this.getOrder()
      this.member = {
        member: {
          userId: this.order?.customer?.user_id || '',
          name: this.order?.customer?.name || '',
          point: 0,
          orders: 0
        },
        phone_number: ''
      }
      this.getMemberData()

    } else if (data.confirm && data.data) {
      this.addFriend = false
      this.member = data.data;
      // call api update order customer
      this.saleApiService.addCustomerToOrder(this.orderNo, this.member.member.userId, this.member.member.name)

    } else {
      this.addFriend = false
      this.member = {
        member: {
          userId: '',
          name: '',
          point: 0,
          orders: 0
        },
        phone_number: ''
      };
      this.saleApiService.removeCustomerFromOrder(this.orderNo).then((res: any) => {
        console.log(res)
        if (res.success) {
          // remove point discount
          const oldDiscountRef = this.getRedeemDiscountRef(this.order)
          if (oldDiscountRef.length > 0 && data.redemption_id !== oldDiscountRef) {
            this.removeDiscount({ confirm: true, type: 'point' })
          }
        }
      });
    }
    console.log(this.member)
  }

  callbackAddFriendClose = () => {
    this.addFriend = false
  }

  callBackAddDiscount = (data: any) => {
    if (data.confirm) {
      this.addDiscount = false
      this.updateDiscount(data);
    } else {
      this.addDiscount = false
    }
  }

  callBackRemoveDiscount = (data: any) => {
    if (data.confirm) {
      this.addDiscount = false
      this.removeDiscount(data);
    } else {
      this.addDiscount = false
    }
  }

  openAddRedeemDiscountDialog = () => {
    if (this.order?.items?.length > 0) {
      this.addRedeemDiscount = true;
    } else {
      this.modalService.openModal('info', 'กรุณาเพิ่มสินค้าก่อนใช้ส่วนลด', '')
    }
  }

  callBackAddRedeemDiscount = async (data: any) => {
    if (data.confirm) {
      const oldDiscountRef = this.getRedeemDiscountRef(this.order)
      if (oldDiscountRef.length > 0 && data.redemption_id !== oldDiscountRef) {
        await this.saleApiService.removeOrderDiscount(this.orderNo, 'point')
      }
      this.addRedeemDiscount = false
      this.updateRedemtionDiscount(data.data);
    } else {
      this.addRedeemDiscount = false
    }
  }

  callBackRemoveRedeemDiscount = (data: any) => {
    if (data.confirm) {
      this.addRedeemDiscount = false
      this.removeDiscount(data);
    } else {
      this.addRedeemDiscount = false
    }
  }

  callBackPayment = async (data: any) => {
    console.log(data)
    console.log(this.order)

    let canPayment = false;
    this.canPrint = data.print;

    const getOrder = await this.salesService.getOneOrder(this.orderNo);
    console.log(getOrder)

    if (getOrder.data.is_paid) {
      canPayment = false
      this.modalService.openModal('info', 'คำสั่งซื้อนี้ชำระเงินเรียบร้อยแล้ว', '').then((res) => {
        this.printSlip();
        this.gotoSalesPage()
      })
    } else {
      if (data.confirm && data.paymentMethod) {
        canPayment = true
      } else if (data.confirm && !data.paymentMethod) {
        canPayment = false
        this.modalService.openModal('fail', 'กรุณาเลือกวิธีการชำระเงิน', '')
      } else {
        canPayment = false
        this.paymentOption = false
      }
    }

    // selected payment method
    if (canPayment) {
      this.utilService.screenLoader(true)
      const body = {
        payment_method_id: data.paymentMethod.id,
        name: data.paymentMethod.name
      }

      //update payment method to order
      const updatePaymentMethod = await this.salesService.updatePaymentMethod(this.orderNo, body)

      if (updatePaymentMethod.success) {
        this.utilService.screenLoader(false)

        if (data.paymentMethod.id === 'qr') { // payment method is qr code
          this.confirmByQrPayment()
        } else if (data.paymentMethod.id === 'cash') {  // payment method is qr code
          this.confirmPaymentByClient()
        } else if (data.paymentMethod.id === 'counter_service') {  // payment method is counter service
          this.confirmPaymentByCounter()
        } else if (data.paymentMethod.id === 'credit') {  // payment method is credit
          this.confirmPaymentByClientCredit()
        } else if (data.paymentMethod.id === 'promptpay_qr') {  // payment method is promptpay_qr
          this.confirmPaymentByPromptPayQr()
        } else { // payment method is other
          this.gotoSalesPage()
        }
      } else {
        this.utilService.screenLoader(false)
        this.modalService.openModal('fail', 'เลือกวิธีชำระเงินไม่สำเร็จ', 'กรุณาลองใหม่อีกครั้ง')
      }

    }
  }

  async confirmByQrPayment() {
    const qrCodeResponse = await this.salesService.generateQrPayment(this.orderNo);
    console.log(qrCodeResponse)
    if (qrCodeResponse.success) {
      const dialogRef = this.dialog.open(QrPaymentComponent, {
        data: { qrcode: qrCodeResponse.data.response.qrcode, amount: qrCodeResponse.data.amount, orderNo: this.orderNo, status: qrCodeResponse.data.status, gbpReferenceNo: qrCodeResponse.data.response.gbpReferenceNo },
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Payment result: ${result}`);
        if (result) {
          this.printSlip();
          this.gotoSalesPage()
        }
      });
    } else {
      this.modalService.openModal('fail', 'สร้าง QR Payment ไม่สำเร็จ', 'กรุณาลองใหม่อีกครั้ง')
    }
  }

  confirmPaymentByClient() {
    // dialog for confirm paid
    const dialogRef = this.dialog.open(CashPaymentComponent, {
      data: { total: this.order.total_price },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async (result: any) => {
      console.log(`Dialog Cash Payment result: ${result}`);
      if (result) {
        const confirmOrder = await this.salesService.confirmOrderByClient(this.orderNo);
        if (confirmOrder.success) {
          this.order = confirmOrder.data
          this.printSlip();
          this.utilService.screenLoader(false)
          this.gotoSalesPage()
        } else {
          this.modalService.openModal('fail', 'ยืนยันคำสั่งซื้อไม่สำเร็จ', 'กรุณาลองใหม่อีกครั้ง')
        }
      }
    })
  }

  confirmPaymentByCounter() {
    // dialog for confirm paid
    const dialogRef = this.dialog.open(CounterPaymentComponent, {
      data: { total: this.order.total_price },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        this.utilService.screenLoader(true)
        const dataUpdate = {
          file: result.urlImage,
          type: result.typeImage,
          note: result.note,
          shop_id: this.shopId
        }
        const confirmOrder: any = await this.saleApiService.updateStatusCounterService(this.orderNo, dataUpdate);
        this.utilService.screenLoader(false)
        if (confirmOrder.success) {
          this.order = confirmOrder.data
          this.printSlip();
          this.gotoSalesPage()
        } else {
          this.modalService.openModal('fail', 'ยืนยันคำสั่งซื้อไม่สำเร็จ', 'กรุณาลองใหม่อีกครั้ง')
        }
      }
    })
  }

  confirmPaymentByClientCredit() {
    // dialog for confirm paid
    const dialogRef = this.dialog.open(CreditPaymentComponent, {
      data: { total: this.order.total_price },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        this.utilService.screenLoader(true)
        const dataUpdate = {
          note: result.note
        }
        const confirmOrder: any = await this.saleApiService.confirmOrderCredit(this.orderNo, dataUpdate);
        this.utilService.screenLoader(false)
        if (confirmOrder.success) {
          this.order = confirmOrder.data
          this.printSlip();
          this.gotoSalesPage()
        } else {
          this.modalService.openModal('fail', 'ยืนยันคำสั่งซื้อไม่สำเร็จ', 'กรุณาลองใหม่อีกครั้ง')
        }
      }
    })
  }

  confirmPaymentByPromptPayQr() {
    const dialogRef = this.dialog.open(QrPromtpayPaymentComponent, {
      data: { amount: this.order.total_price, promptpayNumber: this.promptpay.number, promptpayName: this.promptpay.name },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        this.utilService.screenLoader(true)
        const confirmOrder = await this.salesService.confirmOrderPromptay(this.orderNo);
        if (confirmOrder.success) {
          this.order = confirmOrder.data
          this.printSlip();
          this.utilService.screenLoader(false)
          this.gotoSalesPage()
        } else {
          this.modalService.openModal('fail', 'ยืนยันคำสั่งซื้อไม่สำเร็จ', 'กรุณาลองใหม่อีกครั้ง')
        }
      }
    })
  }

  gotoSalesPage() {
    this.paymentOption = false
    this.router.navigate(['/sales'])
  }

  getUserDetail(user: string) {
    return this.users.find(item => item.user_id === user)
  }

  printSlip() {
    if (this.shopDetail?.is_printer && this.canPrint) {
      const address = this.shopDetail?.address;
      const printer = this.shopDetail?.printer;
      const addressFormat = (address && address.address) ? (address?.address + ' ' + address?.district + ' ' + address?.subdistrict + ' ' + address?.province + ' ' + address?.postal_code) : '';
      const dataPrint = {
        "title": printer?.title || "บิลเงินสด",
        "shopName": printer?.shop_name || "Remax Thailand",
        "branchName": this.shopDetail.name,
        "address": addressFormat,
        "mobile": this.shopDetail.phone_number[0] ? this.shopDetail.phone_number.join(',') : '',
        "orderDate": this.utilService.dayjs(this.order.order_date).format('DD/MM/YYYY HH:mm'),
        "paymentMethod": this.order.payment_method.name,
        "seller": this.getUserDetail(this.order.created.by)?.name || '-',
        "discount": this.order.total_discount,
        "message": printer?.message || "ขอบคุณที่มาอุดหนุนค่ะ",
        "product": this.formatProductDisplay().map(e => ({ name: e.name, qty: e.qty, price: e.unit_price }))
      }

      this.saleApiService.printReceipt(this.shopId, this.orderNo, dataPrint).then((result: any) => {
        console.log(result);
      })
    }
  }

  async onAdd(event: any) {

    const serialNo = event.target.value;

    if (this.isCreating) {
      console.log('Sorry, Creating order');
      return;
    }

    if (serialNo) {
      this.isCreating = true;
      this.processing = true;

      if (this.items.find(e => e.serial_no === serialNo)) {
        this.modalService.openModal('info', 'Ooop!', 'มี barcode นี้ในคำสั่งซื้อแล้ว').then(() => { })
        this.clearInputSerialNoAdd();
        return;
      }

      const resultSerial: any = await this.saleApiService.getOneSerial(serialNo);
      if (!resultSerial.success) {
        this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'เกิดข้อผิดพลาดในการโหลดข้อมูล กรุณาลองใหม่อีกครั้ง')
        this.clearInputSerialNoAdd();
        return;
      } else if (!resultSerial.data) {
        this.modalService.openModal('fail', 'ไม่พบ Barcode ในระบบ', 'กรุณาตรวจสอบ barcode ใหม่อีกครั้ง')
        this.clearInputSerialNoAdd();
        return;
      } else if (resultSerial.data.status === 'out') {
        this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'สินค้านี้อยู่ในขั้นตอนการคืนสำนักงานใหญ่ ไม่สามารถขายได้')
        this.clearInputSerialNoAdd();
        return;
      } else if (resultSerial.data.status === 'claim') {
        this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'สินค้านี้อยู่ในขั้นตอนการเคลม ไม่สามารถขายได้')
        this.clearInputSerialNoAdd();
        return;
      } else if (resultSerial.data.status === 'sold') {
        this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'สินค้านี้ถูกขายแล้ว')
        this.clearInputSerialNoAdd();
        return;
      }


      const sku = resultSerial?.data?.sku;
      const [resultProductShop, resultProduct]: any = await Promise.all([
        this.saleApiService.getProductShopBySku(this.shopId, sku),
        this.saleApiService.getProductBySKu(sku)
      ])

      if (!resultProductShop.success || !resultProduct.success) {
        this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'เกิดข้อผิดพลาดในการโหลดข้อมูล กรุณาลองใหม่อีกครั้ง')
        this.clearInputSerialNoAdd();
        return;
      } else if (!resultProductShop.data) {
        this.modalService.openModal('fail', 'ไม่พบราคาสินค้า', 'กรุณาตรวจสอบใหม่อีกครั้ง')
        this.clearInputSerialNoAdd();
        return;
      }

      // ถ้ามี sku นี้ในคำสั่งซื้อจะใช้ช่องราคาตามที่ Sku นี้เลือกไว้
      const priceConfigId = this.items.find(e => e.sku === sku)?.price_config_id || this.priceSelect;

      const name = resultProduct?.data?.name || '';
      const price = resultProductShop.data.prices.find((e: any) => e.id === priceConfigId)?.price
      if (!price) {
        this.modalService.openModal('fail', 'ไม่พบราคาสินค้า', 'กรุณาตรวจสอบใหม่อีกครั้ง')
        this.clearInputSerialNoAdd();
        return;
      }

      if (this.orderNo) {
        // update
        await this.addOrderItem(sku, name, price, serialNo);
      } else {
        // create
        await this.createOrder(sku, name, price, serialNo);
      }

      this.clearInputSerialNoAdd();

    } else {
      this.modalService.openModal('fail', 'กรุณากรอก Barcode', '')
    }

  }

  clearInputSerialNoAdd() {
    this.serialNoAdd = null;
    this.isCreating = false;
    this.processing = false;
    setTimeout(() => {
      document.getElementById('barcode-add')?.focus()
    }, 10)
  }

  onDelete(event: any) {
    console.log(event.target.value)
    const index = this.items?.findIndex((item: any) => item.serial_no === event.target.value);
    if (this.items[index].is_return) {
      this.openModal('fail', 'ไม่สามารถลบสินค้านี้ได้', 'สินค้ารับคืนไม่สามารถลบออกจากคำสั่งซื้อได้')
      return;
    }

    if (index > -1) {
      this.processing = true;
      this.saleApiService.deleteItems(this.order.order_no, event.target.value).then((response: any) => {
        this.processing = false;

        if (response.success) {
          this.items.splice(index, 1)
          this.calculateOrder(this.items);
          this.calculatePoit(this.shopId, this.orderNo);
          this.clearInputSerialNoDelete();
        } else {
          this.openModal('fail', 'ไม่สามารถลบสินค้านี้ได้', 'กรุณาลองใหม่อีกครั้ง').then(response => {
            this.clearInputSerialNoDelete();
          })
        }

      })
    } else {
      // serial not found in this order
      this.openModal('fail', 'ไม่พบ Barcode ในคำสั่งซื้อนี้', 'กรุณาตรวจสอบ barcode ใหม่อีกครั้ง').then(response => {
        this.clearInputSerialNoDelete();
      })
    }
  }

  clearInputSerialNoDelete() {
    this.serialNoDelete = null;
    setTimeout(() => {
      document.getElementById('barcode-delete')?.focus()
    }, 10)
  }

  async createOrder(sku: string, name: string, price: number, serialNo: string) {
    const dataCreate = {
      order_date: +new Date(),
      shop_id: this.shopId,
      shop_name: this.shopName,
      item: {
        sku,
        name,
        serial_no: serialNo,
        unit_price: price,
        price_config_id: this.priceSelect,
        discount: 0
      }
    }
    await this.saleApiService.createOrder(dataCreate).then((res: any) => {
      if (res.success) {
        this.order = res.data;
        this.orderNo = res.data.order_no;
        this.items = res.data.items;
        this.calculateOrder(this.items)

      } else {
        this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'ไม่สามารถเพิ่มสินค้าได้')
      }
    })
  }

  async addOrderItem(sku: string, name: string, price: number, serialNo: string) {
    const dataUpdate = {
      shop_id: this.shopId,
      sku,
      name,
      serial_no: serialNo,
      unit_price: price,
      price_config_id: this.priceSelect,
      discount: 0
    }
    await this.saleApiService.addOrderItem(this.orderNo, dataUpdate).then((res: any) => {
      console.log(res)
      if (res.success) {
        this.items.push(dataUpdate);
        this.order.items = this.items;
        this.calculateOrder(this.items);
        this.calculatePoit(this.shopId, this.orderNo);
      } else {
        let message = 'ไม่สามารถเพิ่มสินค้าได้';
        if (res.message === 'can not get order') {
          message = 'เกิดข้อผิดพลาดในการโหลดข้อมูลคำสั่งซื้อ กรุณาลองใหม่อีกครั้ง'
        } else if (res.message === 'can not get serial') {
          message = 'เกิดข้อผิดพลาดในการโหลดข้อมูลบาร์โค้ด กรุณาลองใหม่อีกครั้ง'
        } else if (res.message === 'serial number is sold') {
          message = 'สินค้านี้ถูกขายแล้ว'
        } else if (res.message === 'shop id not match') {
          message = 'ไม่พบบาร์โค้ดนี้ในร้านค้า ไม่สามารถขายได้'
        } else if (res.message === 'order status was canceled') {
          message = 'คำสั่งซื้อนี้ถูกยกเลิกแล้ว ไม่สามารถเพิ่มสินค้าได้'
        } else if (res.message === 'order status was completed') {
          message = 'คำสั่งซื้อนี้สำเร็จแล้ว ไม่สามารถเพิ่มสินค้าได้'
        }
        this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', message)
      }
    })
  }

  openModal(type: any, title: string, detail?: string) {
    return this.modalService.openModal(type, title, detail ? detail : '').then((result) => {
      console.log(result);
      return result
    })
  }

  // calculate price, total_discount, total_price in order
  calculateOrder(items: any[]) {
    console.log(items)
    const price = this.calCulatePrice(items)
    const total_discount = this.calCulateTotalDiscount(items)
    const total_price = this.calCulateTotalPrice(price, total_discount)

    this.order = {
      ...this.order,
      price,
      total_discount,
      total_price
    }
  }

  calCulatePrice(items: any[]) {
    let summary = 0;
    for (const item of items) {
      summary += item.unit_price
    }
    return summary
  }

  calCulateTotalDiscount(items: any[]) {
    let summary = 0;
    if (items) {
      for (const item of items) {
        summary += item.discount
      }
    }
    return summary
  }

  calCulateTotalPrice(price: number, total_discount: number) {
    return price - total_discount
  }

  updateDiscount(data: any) {
    const { type, note, name, value, unit, ref } = data;
    const price = this.order.price;
    let discount = 0;

    if (unit === 'amount') {
      discount = parseInt(value);
    } else {
      discount = Math.floor(price * (parseInt(value) / 100));
    }

    if (discount > this.order.price) {
      this.modalService.openModal('info', 'ขออภัย', 'ไม่สามารถใส่ส่วนลดมากกว่ามูลค่าสินค้าได้')
      return;
    }

    const dataUpdate = {
      type: type,
      unit: unit,
      value: parseInt(value),
      name: name,
      discount: discount,
      note: note,
      ref: ref
    }

    this.saleApiService.updateOrderDiscount(this.orderNo, dataUpdate).then((res: any) => {
      console.log(res)
      if (res.success) {
        this.items = res.data.items
        this.order = res.data;
        this.calculateOrder(this.items)
      }
    })
  }

  updateRedemtionDiscount(selectedRedemtion: any) {

    if (selectedRedemtion.discount_amount > this.order.price) {
      this.modalService.openModal('info', 'ขออภัย', 'ไม่สามารถใส่ส่วนลดมากกว่ามูลค่าสินค้าได้')
      return;
    }

    const body = {
      "ref": selectedRedemtion.redemption_id
    }

    this.saleApiService.updateOrderRedemptionDiscount(this.orderNo, body).then((res: any) => {
      console.log(res)
      if (res.success) {
        this.items = res.data.items
        this.order = res.data;
        this.calculateOrder(this.items)
      }
    })
  }

  removeDiscount(data: { confirm: boolean, type: string }) {
    console.log(data)
    if (data.type) {
      this.saleApiService.removeOrderDiscount(this.orderNo, data.type).then((res: any) => {
        console.log(res)
        if (res.success) {
          this.items = res.data.items
          this.order = res.data;
          this.calculateOrder(this.items)
        }
      })
    }
  }

  async getPriceConfig() {
    // get price slots by shop
    const result = await this.saleApiService.getPriceConfig(this.shopId).then((response: any) => {
      if (!response.success) return [];
      return response.data?.filter((item: any) => item.active).map((item: any) => {
        return {
          key: item.price_config_id,
          name: item.name,
          isDefault: item.is_default
        }
      })
    })
    this.priceSlots = result;
    this.priceSelect = this.priceSlots.filter(item => item.isDefault)[0]?.key ?? this.priceSlots[0]?.key;
  }

  async orderPriceConfigChange() {
    console.log(this.priceSelect)
    if (this.orderNo) {
      await this.saleApiService.updateOrderPriceConfig(this.orderNo, this.priceSelect).then((result: any) => {
        if (result.success) {
          this.items = result.data.items
          this.order = result.data;
          this.calculateOrder(this.items)
        }
      });
    }
  }

  async itemPriceConfigChange(sku: string, itemPriceSelect: any) {
    const updateBody = {
      items: [
        {
          sku,
          price_config_id: itemPriceSelect
        }
      ]
    }
    this.saleApiService.updateItemPriceConfig(this.orderNo, updateBody).then((result: any) => {
      if (result.success) {
        this.items = result.data.items
        this.order = result.data;
        this.calculateOrder(this.items)
      }
    })
  }

  async getMemberData() {
    if (!this.order.customer) return;
    console.log(this.order);
    let memberData = {
      member: {
        userId: '',
        name: '',
        point: 0,
        orders: 0
      },
      phone_number: ''
    }
    // 1. get user info that include phone number
    // 2. get point balance
    // 3. get customer bill

    memberData.member.userId = this.order.customer.user_id;
    memberData.member.name = this.order.customer.name;

    const member: any = await this.saleApiService.getCustomerByUserId(this.order.customer.user_id);
    if (!member.success) {
      this.isMember = false;
      this.memberData = {
        userId: memberData.member.userId,
        name: memberData.member.name,
        point: 0,
        orders: 0,
        phone_number: ''
      };
      this.member = memberData;
      return;
    };

    console.log(member)
    memberData.phone_number = member.data.phone

    const pointBalance: any = await this.saleApiService.getCustomerPointBalace(memberData.member.userId, this.authService.selectedShop?.shop_id || '')
    const totalBill: any = await this.saleApiService.getCustomerTotalBill(memberData.member.userId, this.authService.selectedShop?.shop_id || '')
    memberData.member.point = pointBalance.data ?? 0;
    memberData.member.orders = totalBill.data ?? 0;

    // for sale-add-friend component
    this.isMember = true;
    this.memberData = {
      userId: memberData.member.userId,
      name: memberData.member.name,
      point: memberData.member.point,
      orders: memberData.member.orders,
      phone_number: memberData.phone_number
    }
    this.member = memberData;
    console.log(this.member)
    this.confirmMember = true;
    console.log(this.isMember)
  }

  calculatePoit(shopId: string, orderNo: string) {
    this.salesService.getPointOrder(shopId, orderNo).then(response => {
      if (response.success) {
        this.order[`points`] = response.data
      } else {
        this.order[`points`] = 0
        console.log(response.message)
      }
    })
  }

  getRedeemDiscount(order: any) {
    const discounts = order?.discounts || []
    return discounts.find((item: any) => item?.type === 'point')?.discount || 0
  }

  getRedeemDiscountRef(order: any) {
    const discounts = order?.discounts || []
    return discounts.find((item: any) => item?.type === 'point')?.ref || ''
  }
}