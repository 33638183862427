import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';
import FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class StockOutService {

  state = 'ongoing';  // ongoing, success

  constructor() { }

  async generateStockOutReport(data: any[], fileName: string) {
    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet('สินค้าคืนสำนักงานใหญ่');
    worksheet.columns = [
      { header: 'ร้านค้า', key: 'shop_name', width: 30 },
      { header: 'หมายเลขการคืนสินค้า', key: 'stock_out_no', width: 30 },
      { header: 'วันที่คืนสินค้า', key: 'stock_out_date', width: 20 },
      { header: 'SKU', key: 'sku', width: 15 },
      { header: 'ชื่อสินค้า', key: 'name', width: 40 },
      { header: 'บาร์โค้ด', key: 'serial_no', width: 20 },
      { header: 'พนักงาน', key: 'saler', width: 20 }
    ];

    data.forEach((row) => {
      worksheet.addRow(row);
    });

    worksheet.getRow(1).font = { bold: true, color: { argb: '000' } };

    // Generate the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    this.saveExcelFile(buffer, `${fileName}.xlsx`);
  }

  private saveExcelFile(buffer: ArrayBuffer, filename: string) {
    const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    FileSaver.saveAs(data, filename);
  }

}
