@if(!isLoading) {
<div class="p-4 pb-32">
    <div class="divide-y bg-white rounded-lg border px-4">
        <div class="flex items-center w-full space-x-4 px-2">
            <img class="w-14" src="assets/images/shopping-cart.png">
            <div class="py-4">
                <div class="font-bold text-lg">
                    #{{returnNo}}
                </div>
                <div class="text-sm text-gray-600">
                    หมายเลขการรับคืน
                </div>
            </div>
        </div>
        <div class="grid grid-cols-3 w-full divide-x py-4">
            <div class="text-center w-full pr-2">
                <div class="font-bold">
                    {{displayDate(orderDetail.return_date)}}
                </div>
                <div class="text-sm text-gray-600">
                    วันที่รับคืน
                </div>
            </div>
            <div class="text-center w-full px-2">
                <div class="font-bold">
                    {{displayTime(orderDetail.return_date)}}
                </div>
                <div class="text-sm text-gray-600">
                    เวลารับคืน
                </div>
            </div>
            <div class="text-center w-full px-2">
                <div class="text-sm font-medium" [ngClass]="{
                            ' text-yellow-500':(orderDetail.status === 'ongoing' || orderDetail.status === 'waiting_exchange' || orderDetail.status === 'waiting_refund'),
                            ' text-red-500':orderDetail.status=== 'canceled',  
                            'text-lime-500':orderDetail.status === 'completed' || orderDetail.status === 'refunded'
                            }">
                    {{displayStatusOrder(orderDetail.status)}}
                </div>
                <div class="flex justify-center">
                    <p class="text-sm font-medium text-gray-600 bg-gray-100 rounded-lg px-2 py-1 w-fit">
                        {{orderDetail.refund_detail ? 'รับคืน' : 'เปลี่ยนสินค้า'}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="py-4 border-b ">
        <div class="flex gap-4">
            <fa-icon [icon]="faBasketShopping"></fa-icon>
            <div class="font-bold">หมายเลขคำสั่งซื้อเดิม</div>
        </div>
        <div class="ml-[30px]">
            <a routerLink="/sales/sale-detail" [queryParams]="{no: orderDetail.order_no}"
                class="text-blue-500">#{{orderDetail.order_no}}</a>
        </div>
    </div>
    <div class="py-4 space-y-3 border-b">
        <div class="flex gap-4">
            <fa-icon [icon]="faHandHoldingDollar"></fa-icon>
            <div class="font-bold">ข้อมูลการคืนเงิน</div>
        </div>
        <div class="">
            @if(orderDetail.refund_detail) {
            <div class="grid grid-cols-1 w-full divide-y md:divide-y-0 md:divide-x bg-white rounded-lg border p-2 md:p-4 text-sm"
                [ngClass]="{'md:grid-cols-5' : orderDetail?.refund_detail?.payment_method === 'bank_transfer', 'md:grid-cols-2' : orderDetail?.refund_detail?.payment_method === 'cash'}">
                <div class="w-full flex flex-row py-1 justify-between md:flex-col md:text-center">
                    <div class="">วิธีการคืน</div>
                    <div class="font-bold">{{displayType(orderDetail?.refund_detail?.payment_method)}}</div>
                </div>
                @if(orderDetail?.refund_detail?.payment_method === 'bank_transfer'){
                <div class="w-full flex flex-row py-1 justify-between md:flex-col md:text-center">
                    <div class="">ธนาคาร</div>
                    <div class="font-bold">{{orderDetail?.refund_detail?.bank}}</div>
                </div>
                <div class="w-full flex flex-row py-1 justify-between md:flex-col md:text-center">
                    <div class="">บัญชี</div>
                    <div class="font-bold">{{orderDetail?.refund_detail?.acc_number}}</div>
                </div>
                <div class="w-full flex flex-row py-1 justify-between md:flex-col md:text-center">
                    <div class="">ชื่อบัญชี</div>
                    <div class="font-bold">{{orderDetail?.refund_detail?.acc_name}}</div>
                </div>
                }
                <div class="w-full flex flex-row py-1 justify-between md:flex-col md:text-center">
                    <div class="">โทรศัพท์</div>
                    <div class="font-bold">{{orderDetail?.refund_detail?.phone_number}}</div>
                </div>
            </div>
            <div class="mt-2 text-red-500">* หมายเหตุ {{orderDetail?.refund_detail?.note}}</div>

            }@else {
            <div class="ml-[30px]">-</div>
            }
        </div>
    </div>
    <div class="py-4 border-b ">
        <div class="flex gap-4">
            <fa-icon [icon]="faUser"></fa-icon>
            <div class="font-bold">ลูกค้า</div>
        </div>
        <div class="ml-[30px]">
            @if(orderDetail?.customer) {
            คุณ {{orderDetail?.customer?.name || '-'}}
            }@else {
            -
            }
        </div>
    </div>
    <div class="py-4 space-y-3">
        <div class="font-bold">
            สินค้าที่รับคืน
        </div>

        <div class="space-y-2">
            @for (item of formatProductDisplay(); track $index) {
            <div class="grid grid-cols-5 md:grid-cols-8 xl:grid-cols-12 gap-2 border p-2 bg-white rounded-lg">
                <div class="col-span-1">
                    <div class="w-full aspect-square">
                        <img [src]="'https://storage.remaxthailand.co.th/img.php?src=img/product/' + item.sku + '/1.jpg'"
                            [alt]="item.sku" class="rounded-sm"
                            onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                    </div>
                </div>
                <div class="col-span-3 md:col-span-6 xl:col-span-10 text-sm md:text-base line-clamp-3 h-[56px]">
                    {{ item.name }}
                </div>
                <div class="col-span-1 text-right pr-1">
                    <div class="text-sm md:text-base">
                        x {{ item.qty| number }}
                    </div>
                    <div class="font-bold text-sm md:text-base">
                        {{ item.unit_price | number }}฿
                    </div>
                </div>
            </div>
            }
        </div>
    </div>
    <div class="pb-4 flex justify-end">
        <div class="w-full md:w-2/4 xl:w-1/3">
            <div class="flex justify-between">
                <div>รวม</div>
                <div>{{orderDetail?.price | number}}</div>
            </div>

            @if (orderDetail.total_discount) {
            <div>
                <div class="flex justify-between">
                    <div>ส่วนลด</div>
                    <div class="text-red-500">-{{orderDetail.total_discount | number}}</div>
                </div>

            </div>
            }
        </div>
    </div>
    <div class="flex justify-end">
        <div class="w-full md:w-2/4 xl:w-1/3 py-4 border-dashed border-t border-gray-300">
            <div class="flex justify-between">
                <div class="text-lg font-bold">ยอดคืนสินค้าเดิม</div>
                <div class="text-2xl font-bold text-blue-600">{{orderDetail.total_price | number}}</div>
            </div>
            @if (orderDetail.status === 'ongoing') {
            <div class="mt-8">
                <button class="btn-light text-red-500 w-full" (click)="cancelReturnOrder()">ยกเลิกรับคืน</button>
            </div>
            }


        </div>
    </div>
</div>
@if (orderDetail.status === 'ongoing') {
<div
    class="w-full md:w-[calc(100%-8rem)] fixed bottom-0 bg-white border-t border-x h-fit p-4 rounded-t-2xl shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] md:shadow-none">
    <!-- If the status is 'success', do not display. -->
    <button class="btn-primary w-full" routerLink="/exchange/exchange-create" [queryParams]="{return_no: returnNo}">
        ดำเนินการต่อ
    </button>
</div>
}
}@else {
<div class="flex justify-center pt-40">
    <app-loader></app-loader>
</div>
}