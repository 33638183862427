import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { InventoryService } from '../../services/inventory/inventory.service';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { Router } from '@angular/router';
import { AuthGuardService } from '../../../../core/services/auth-guard/auth-guard.service';

@Component({
  selector: 'app-inventory-counting',
  templateUrl: './inventory-counting.component.html',
  styleUrl: './inventory-counting.component.css'
})
export class InventoryCountingComponent implements OnInit, OnDestroy {
  /**
   * ดึงข้อมูลการนับสต๊อกจาก api พี่เอิบ
   *
   * ถ้ากดยืนยันการนับรอบนี้โดย manager แล้ว 
   * สิ่งที่ API ไปทำต่อ คือ
   * #กรณีที่ shop นี้ยังไม่เคยมีสต๊อกในร้านเลย จะทำการสร้าง receipt ก่อน#
   * เก็บข้อมูลจาก api GET:https://inventory.powerbolt.workers.dev/stock/{shop_id} ใน mongoDb
   * หลังจากนั้น ยิง api DELETE:https://inventory.powerbolt.workers.dev/stock/{shop_id} เพื่อ ลบ การนับสต๊อกรอบนี้ ของพี่เอิบ
   */
  soundEffectSuccess: HTMLAudioElement;
  soundEffectWrong: HTMLAudioElement;
  soundEffectScan: HTMLAudioElement;

  @ViewChild('barcodeInput') barcodeInputElement: ElementRef | undefined;

  faBarcode = faBarcode;

  subscribeInventory: any;

  inventory: any = [];
  stock: number = 0;
  counted: number = 0;

  barcodeValue: any;

  scanning = false;

  productCounting: any;

  shopId: string = this.authService.selectedShop?.shop_id || '';
  // shopId = '00000999'

  loading = false;

  processing = false

  inventoryNew: any = [];

  state = 'shop-product'

  constructor(
    private authService: AuthService,
    private inventoryService: InventoryService,
    private modalService: ModalService,
    private router: Router,
    private authGaurdService: AuthGuardService
  ) {
    this.soundEffectSuccess = new Audio('/assets/sounds/success.mp3');
    this.soundEffectWrong = new Audio('/assets/sounds/wrong.mp3');
    this.soundEffectScan = new Audio('/assets/sounds/blink.mp3')
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.barcodeInputElement?.nativeElement.focus();
    }, 10);

    this.loading = true
    this.getInventory()
    this.getInventoryStatusByShop()
  }

  ngOnDestroy(): void {
    this.subscribeInventory.unsubscribe()
  }

  getInventory() {
    this.subscribeInventory = this.inventoryService.getData(this.shopId).subscribe(data => {
      console.log(data)
      if (data.length > 0) {
        // this.inventory = data
        this.inventory = this.sortInventory(data)
        this.stock = data.reduce((acc, val) => { return acc + val.qty; }, 0)
        this.counted = data.reduce((acc, val) => { return acc + val.counted; }, 0)
        console.log(this.stock)
        this.loading = false
      } else {
        this.modalService.openModal('fail', 'ไม่พบข้อมูลการนับสต๊อก', '').then((result: any) => {
          this.router.navigate(['/inventory'])
        })
      }
    });
  }

  sortInventory(inventory: any[]) {
    const finishedCounting = []
    const ongoingCounting = []

    const listSize = inventory.length
    for (let index = 0; index < listSize; index++) {
      if ((inventory[index].qty - inventory[index].counted) > 0) {
        ongoingCounting.push(inventory[index])
      } else {
        finishedCounting.push(inventory[index])
      }
    }

    return [...ongoingCounting, ...finishedCounting]
  }

  getInventoryStatusByShop() {
    this.inventoryService.getInventory(this.shopId).then(async (inventory: any) => {
      if (inventory.success) {
        this.getNewProduct(inventory.data.new)
      }
    });
  }

  async getNewProduct(dataNew: any) {
    const body = {
      serial: dataNew
    }
    const newProductDetail = await this.inventoryService.getProductBySerialNo(body).then((response: any) => {
      console.log(response)
      if (response.success) {
        return response.data
      } else {
        return {}
      }
    })
    this.inventoryNew = []
    for (const serial of dataNew) {
      if (newProductDetail[serial]) {
        this.inventoryNew.push({
          serial_no: serial,
          ...newProductDetail[serial]
        })
      } else {
        this.inventoryNew.push({
          serial_no: serial
        })
      }
    }
  }

  async scanBarcode() {
    this.processing = true;
    console.log(this.barcodeValue)
    if (!this.barcodeValue) {
      this.soundEffectWrong.play();
      this.modalService.openModal('fail', 'กรุณากรอกบาร์โค้ดสินค้า', '')
      return false
    }
    const product = await this.getProductBySerialNo()
    this.scanning = true;
    const body = {
      serial: this.barcodeValue
    }

    this.inventoryService.inventoryCounting(this.shopId, body).then(async (response: any) => {
      console.log(response)
      if (response.success) {
        console.log(product)
        if (product) {
          this.soundEffectScan.play();
          this.productCounting = this.inventory.find((e: any) => e.sku === product.sku) || product
        } else {
          this.soundEffectWrong.play();
          this.modalService.openModal('fail', 'ไม่พบ Barcode สินค้านี้', 'กรุณาลองใหม่อีกครั้ง').then(() => { })
          this.productCounting = {}
        }

        this.getInventoryStatusByShop();
      } else {
        this.soundEffectWrong.play();
        let message = 'นับสต๊อกสินค้านี้ไม่สำเร็จ'
        if (response?.message === 'Serial already exists') {
          message = 'สินค้านี้ถูกนับแล้ว'
        }
        this.modalService.openModal('fail', message, 'กรุณาลองใหม่อีกครั้ง').then(() => {
          this.productCounting = undefined;
          this.scanning = false;
        })
      }

      this.processing = false;
    })
    this.barcodeValue = null
    return true


  }

  progressProductCounting() {
    const product = this.inventory.find((item: any) => item.sku === this.productCounting.sku)
    this.productCounting.counted = product && product.counted ? product.counted : 0
    this.productCounting.qty = product && product.qty ? product.qty : 0

    return product && product.counted && product.qty ? Math.ceil((product.counted / product.qty) * 100) : 0
  }

  getProductBySerialNo() {
    console.log(this.barcodeValue)
    const serial = {
      serial: [this.barcodeValue]
    }
    return this.inventoryService.getProductBySerialNo(serial).then((response: any) => {
      response
      if (response.success) {
        return response.data[this.barcodeValue].sku ? response.data[this.barcodeValue] : null
      } else {
        return null
      }
    })
  }

  calcuatePercent(count: number, total: number) {
    return count && total ? Math.ceil((count / total) * 100) : 0
  }

  confirmInventory() {
    if (!this.canConfirm()) return
    this.modalService.openModal('confirm', 'ยืนยันว่าจำนวนสินค้านี้เป็นความจริง', 'สินค้าที่ไม่ถูกนับจะถือว่าเป็นของหาย ยืนยันแล้วไม่สามารถย้อนกลับได้').then(async (result: any) => {
      console.log(result)
      if (result) {
        this.subscribeInventory.unsubscribe()
        this.processing = true;
        const body = {
          shop_id: this.shopId
        }
        this.inventoryService.confirmInventory(body).then((response: any) => {
          console.log(response)
          if (response.success) {
            this.inventoryService.syncStockProduct(this.shopId, {}).then(res => {
              this.router.navigate(['/inventory'])
            })
          } else {
            this.getInventory()
            this.modalService.openModal('fail', 'ยืนยันสต๊อกสินค้าไม่สำเร็จ', 'กรุณาลองใหม่อีกครั้ง')
          }
          this.processing = false;
        })
      }
    })
  }

  deleteInventory() {
    if (!this.canConfirm()) return
    this.modalService.openModal('confirm-warning', 'ต้องการยกเลิกการข้อมูลการนับสต๊อก', 'รายการที่นับแล้วทั้งหมดจะถูกยกเลิก').then(async (result: any) => {
      console.log(result)
      if (result) {
        this.subscribeInventory.unsubscribe()
        this.inventoryService.deleteInventory(this.shopId).then((response: any) => {
          if (response.success) {
            this.router.navigate(['/inventory'])
          } else {
            this.getInventory()
            this.modalService.openModal('fail', 'ไม่สามารถยกเลิกได้ในขณะนี้', 'กรุณาลองใหม่อีกครั้ง')
          }
        })
      }
    });
  }

  canConfirm() {
    return this.authGaurdService.canAction(['admin'])
  }
}
