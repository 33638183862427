<div class="p-4">
    <div class="pb-4">
        <div class="relative">
            <input #barcodeInput [(ngModel)]="barcodeValue" type="text" id="barcode" class="input !pr-12"
                placeholder="บาร์โค้ดสินค้า" autocomplete="off" (keyup.enter)="scanBarcode()">
            <fa-icon [icon]="faBarcode" class="absolute right-4 -mt-9 text-2xl"></fa-icon>
        </div>
    </div>
    <div class="mt-4 space-y-2">
        @if(isLoaded){
        @if (productDetail) {
        <div class="grid grid-cols-1 md:grid-cols-5 gap-4">
            <div class="md:col-span-3 space-y-4">
                <ng-container *ngTemplateOutlet="ProductDetailTemplate"> </ng-container>
                <ng-container *ngTemplateOutlet="ProductPriceTemplate"> </ng-container>
            </div>
            <div class="md:col-span-2 space-y-4">
                <ng-container *ngTemplateOutlet="ProductPointTemplate"> </ng-container>
                <ng-container *ngTemplateOutlet="ProductTimelineTemplate"> </ng-container>
            </div>
        </div>
        }
        @else {
        <div class="pt-20 text-center">
            <div class="flex flex-col justify-center items-center w-full">
                <img src="../../../assets/images/scan-barcode.svg" class="h-20 aspect-auto">
            </div>
            <div class="text-gray-500">สแกนสินค้าที่ต้องการตรวจสอบ</div>
        </div>
        }
        }
        @else {
        <div class="flex justify-center pt-40">
            <app-loader></app-loader>
        </div>
        }
    </div>
</div>

<!-- รายละเอียด -->
<ng-template #ProductDetailTemplate>
    <div class="card flex flex-col md:flex-row gap-6">
        <div class="w-52 self-center">
            <img src="https://storage.remaxthailand.co.th/img.php?src=img/product/{{productDetail.sku}}/1.jpg" alt=""
                class="w-fullrounded"
                onerror="this.onerror=null; this.src='assets/images/warranty.png'">
        </div>
        <div class="w-full">
            <div class="text-lg font-semibold">{{productDetail.name}}</div>
            <ul class="list-disc mt-4 pl-4 text-base">
                <li>
                    <div class="flex w-full">
                        <div class="w-1/3">Barcode</div>
                        <div class="w-2/3 text-gray-500">{{productBarcode}}</div>
                    </div>
                </li>
                <li>
                    <div class="flex w-full">
                        <div class="w-1/3">SKU</div>
                        <div class="w-2/3 text-gray-500">{{productDetail.sku}}</div>
                    </div>
                </li>
                <li>
                    <div class="flex w-full">
                        <div class="w-1/3">หมวดหมู่</div>
                        <div class="w-2/3 text-gray-500">{{productDetail.category.name}}</div>
                    </div>
                </li>
                <li>
                    <div class="flex w-full">
                        <div class="w-1/3">แบรนด์</div>
                        <div class="w-2/3 text-gray-500">{{productDetail.brand.name}}</div>
                    </div>
                </li>
            </ul>
            @if (productDetail.warranty) {
            <div
                class="mt-4 bg-blue-50 text-blue-800 text-sm px-2 py-2 font-medium rounded flex items-center justify-center space-x-2">
                <fa-icon [icon]="faShieldAlt" class=""></fa-icon>
                <div class="">ระยะเวลารับประกัน {{formatWarranty(productDetail.warranty)}}</div>
            </div>
            }
            @else {
            <div
                class="mt-4 bg-yellow-50 text-yellow-800 text-sm px-2 py-2 font-medium rounded flex items-center justify-center space-x-2">
                <fa-icon [icon]="faShieldAlt" class=""></fa-icon>
                <div class="">ไม่มีประกัน</div>
            </div>
            }
        </div>
    </div>
</ng-template>

<!-- ราคาสินค้า -->
<ng-template #ProductPriceTemplate>
    <div class="card">
        <div class="text-base font-medium mb-4">ราคาสินค้า</div>
        <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
            @for (item of productPrice; track $index) {
            <div class="flex gap-2 items-center">
                <div class="">
                    +
                </div>
                <div class="">
                    <div class="text-gray-500 text-sm">{{item.name}}</div>
                    <div class="font-semibold">{{item.price | number}}</div>
                </div>
            </div>
            }
        </div>
    </div>
</ng-template>

<!-- ประวัติการขาย -->
<ng-template #ProductTimelineTemplate>
    <div class="card">
        <div class="text-base font-medium mb-4">ไทม์ไลน์สินค้า</div>
        <div class="">
            @if (transactions && transactions.length > 0) {
            <ol class="relative border-s border-gray-200 ml-2">
                @for (item of transactions; track $index) {
                <li class="ms-6" [ngClass]="{'mb-10' : $index !== transactions.length-1}">
                    <span
                        class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white">
                        <svg class="w-2.5 h-2.5 text-blue-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor" viewBox="0 0 20 20">
                            <path
                                d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                        </svg>
                    </span>
                    <div class="flex items-center mb-1 text-base font-semibold text-gray-900">
                        {{item.detail}}
                        @if ($index===0) {
                        <span
                            class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded ms-3">ล่าสุด</span>
                        }
                    </div>
                    <div class="block mb-2 text-sm font-normal leading-none text-gray-400">เมื่อ
                        {{item.date || '-'}}</div>
                    <div class="flex space-x-2 text-sm text-gray-500">
                        <p>ที่ {{item.shop}}</p>
                        @if (item.customer) {
                        <fa-icon [icon]="faArrowRight" class=""></fa-icon>
                        <p>{{item.customer}}</p>
                        }
                    </div>
                    <p class="text-sm text-gray-500">คำสั่งซื้อ : {{item.orderNo}}</p>
                </li>
                }
            </ol>
            @if(isShopSerial() && canReceipt() && (!existedSerial)){
            <button class="btn-primary w-full mt-4" (click)="confirmReceipt()">สร้างใบรับเข้า</button>
            }
            } @else {
            <div class="w-full text-center bg-yellow-50 text-yellow-600 text-sm font-medium rounded p-2">
                ไม่พบประวัติการขายของ
                barcode นี้</div>
            }
        </div>
    </div>
</ng-template>

<!-- แต้มสินค้า -->
<ng-template #ProductPointTemplate>
    <div class="card">
        <div class="flex justify-between items-center">
            <div class="text-base font-medium">Point ที่ได้</div>
            <div class="bg-blue-50 text-blue-800 text-sm py-1 px-4 font-semibold rounded">+{{productPoint}}</div>
        </div>
    </div>
</ng-template>

@if (processing) {
<div class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-5 z-50 flex justify-center items-center">
    <span class="loader-processing !w-9 !h-9 !border-8 -mt-10"></span>
</div>
}