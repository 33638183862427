<div class="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-30" *ngIf="open" (click)="close()">
</div>
<div class="component-slide" [ngClass]="{'before-position-slide': open, 'after-position-slide' : !open}">
    <div class="relative w-full h-svh"
        [ngClass]="{'transform transition-all md:opacity-100 duration-700 delay-300': open, 'transform transition-all md:opacity-0 duration-300 delay-75' : !open}">
        <div class="absolute top-0 flex gap-6 px-4 bg-white h-12 w-full shadow-sm items-center cursor-pointer z-10"
            (click)="close()">
            <button class="">
                <fa-icon [icon]="faChevronDown" class="text-xl text-gray-600"></fa-icon>
            </button>
            <div class="text-lg font-bold">
                ส่วนลดการแลกคะแนน
            </div>
        </div>

        @if(isLoading) {
        <div class="flex justify-center pt-40">
            <app-loader></app-loader>
        </div>
        }@else {

        @if (state === 'no-member') {
        <div class="flex flex-col p-4 pt-16 h-full overflow-y-auto">
            <div class="pt-10">
                <div class="flex justify-center items-center mb-10">
                    <img src="../../../../../assets/images/no-is-member.png" class="w-[120px] h-[120px]" />
                </div>
                <div class="text-xl font-bold text-center mb-2">ลูกค้ายังไม่ได้เป็นสมาชิก</div>
                <div class="text-center mb-4 px-4">กรุณาสมัครสมาชิกหรือยืนยันข้อมูลให้เรียบร้อย สามารถสมัครได้ที่เมนู
                    สมาชิก ที่ Line OA</div>
            </div>
        </div>
        }

        @if (state === 'send-otp') {
        <div class="flex flex-col justify-between p-4 pt-16 h-full overflow-y-auto">
            <div class="space-y-8 mt-4">
                <div class="space-y- grid grid-cols-2 gap-4">
                    <div class="text-center bg-gray-100 py-4 rounded-lg">
                        <div class="flex justify-center items-center">
                            <div class="flex justify-center w-fit pr-2">
                                <img src="/assets/images/coin.png" class="w-5  h-5"
                                    onerror="this.onerror=null; this.src='assets/images/warranty.png'" />
                            </div>
                            <div class="text-3xl font-bold text-gray-600">{{pointsBalance | number}}</div>
                        </div>
                        <div class="text-sm">คะแนนสะสม</div>
                    </div>
                    <div class="text-center bg-gray-100 py-4 rounded-lg">
                        <div>
                            <div>
                                <span class="text-2xl text-red-600">฿</span>
                                <span class="text-3xl font-bold text-red-600">{{maxDiscount | number}}</span>
                            </div>
                        </div>
                        <div class="text-sm">เป็นเงินประมาณ</div>
                    </div>
                </div>
                <div class="text-sm text-gray-400">
                    *หมายเหตุ
                    <p>- สามารถแลกได้เมื่อมีแต้มสะสมขั้นต่ำ {{minPointsBalance}} ขึ้นไป</p>
                    <p>- มูลค่าต่อแต้ม เท่ากับ {{pointValue}} บาท</p>
                    <p>- เงินส่วนลดจากแต้ม ต้องไม่เกินมูลค่ารวมสินค้า หลังหักส่วนลดทั่วไปแล้ว</p>
                </div>
                <div class="pt-4">
                    <form [formGroup]="form" (ngSubmit)="createRedemption()" class="space-y-8">
                        <div class="space-y-4">
                            <label for="discount">ต้องการส่วนลด (บาท)</label>
                            <input inputmode="numeric"
                                class="bg-white border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5 h-[60px] disabled:bg-gray-100 disabled:cursor-not-allowed text-center text-2xl font-bold placeholder:font-normal placeholder:text-2xl"
                                type="number" id="discount" formControlName="discount" placeholder="฿0"
                                (keyup)="onKeyUpDiscount($event)" max="maxDiscount">
                            <button class="px-3 p-0.5 border rounded-full bg-orange-400 w-fit"
                                *ngIf="canUseDiscount > 0" (click)="useMaxDiscount(canUseDiscount)" type="button">
                                + ลดได้สูงสุดอีก {{canUseDiscount | number}} บาท
                            </button>
                        </div>
                        <div
                            class="absolute bottom-0 right-0 flex px-4 bg-white pt-2 pb-4 w-full shadow-sm justify-center items-center">
                            <button type="submit" class="btn-primary w-full" [disabled]="form.invalid">ยืนยันใช้
                                {{form.get('points')?.value |
                                number}} แต้มแลก</button>
                        </div>
                    </form>
                </div>
                <div id="recaptcha-container"></div>
            </div>
        </div>
        }

        @if (state === 'verify-otp') {
        <div class="flex flex-col p-4 pt-16 h-full overflow-y-auto">
            <div class="pt-10">
                <div class="flex justify-center items-center mb-10">
                    <img src="../../../../../assets/images/security.png" class="w-[120px] h-[120px]" />
                </div>
                <div class="text-xl font-bold text-center mb-4"> ยืนยันรหัส OTP </div>
                <div class="text-center mb-6 px-6 text-sm"> ระบบได้ส่ง SMS ที่มีรหัส OTP ไปยังเบอร์ <span
                        class="font-semibold">{{securePhoneData(phoneNumber || '')}}</span> เรียบร้อยแล้ว</div>
                <div id="otp-inputs" class="w-full grid grid-cols-6 gap-2 px-6 mb-6">
                    <input type="text" inputmode="numeric" #otpInput maxlength="1" class="input text-center"
                        (input)="onOtpInput($event, 0)" (keydown)="onKeyDown($event, 0)">
                    <input type="text" inputmode="numeric" #otpInput maxlength="1" class="input text-center"
                        (input)="onOtpInput($event, 1)" (keydown)="onKeyDown($event, 1)">
                    <input type="text" inputmode="numeric" #otpInput maxlength="1" class="input text-center"
                        (input)="onOtpInput($event, 2)" (keydown)="onKeyDown($event, 2)">
                    <input type="text" inputmode="numeric" #otpInput maxlength="1" class="input text-center"
                        (input)="onOtpInput($event, 3)" (keydown)="onKeyDown($event, 3)">
                    <input type="text" inputmode="numeric" #otpInput maxlength="1" class="input text-center"
                        (input)="onOtpInput($event, 4)" (keydown)="onKeyDown($event, 4)">
                    <input type="text" inputmode="numeric" #otpInput maxlength="1" class="input text-center"
                        (input)="onOtpInput($event, 5)" (keydown)="onKeyDown($event, 5)">
                </div>
                <button (click)="confirmOtp()" class="btn-secondary w-full">ยืนยัน OTP</button>
                <div *ngIf="isOtpSent && errorMessage.length > 0" class="py-2 text-red-500 text-sm">{{errorMessage}}
                </div>
                <div class="pt-6">
                    <button (click)="resendOtp()" type="button" class="w-full underline text-center text-sm"
                        *ngIf="canResend">
                        ไม่ได้รับ OTP ขอใหม่อีกครั้ง
                    </button>
                    <div *ngIf="!canResend" class="text-center text-gray-500 text-sm">
                        <span>ขอ OTP ใหม่ได้ในอีก</span>
                        <span class="font-medium pl-2">{{ countdown }}s</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="absolute bottom-0 flex px-4 bg-white pt-2 pb-4 w-full shadow-sm justify-center items-center">
            <div class="flex flex-col gap-4 w-full">
                <div class="flex justify-center pt-2">
                    <button class="btn-light underline" (click)="cancel()">ยกเลิก</button>
                </div>
            </div>
        </div>
        }
        @if (state === 'select-discount') {
        <div class="flex flex-col p-4 pt-16 h-full overflow-y-auto">
            <div class="pt-6 grid grid-cols-1 gap-2">
                <!-- <div class="py-2">ส่วนลดที่ใช้ได้</div> -->
                @for (redemption of allRedemptiontion; track $index) {
                <button class="bg-gray-200 py-4 rounded-lg px-4 w-full" (click)="selecteDiscount(redemption)"
                    [ngClass]="{'bg-yellow-500': selectedRedemptionId === redemption.redemption_id}">
                    <div class="flex space-x-2">
                        <div>ส่วนลด</div>
                        <div>{{redemption.discount_amount | number}}</div>
                    </div>
                    <div class="flex space-x-2 text-xs text-gray-500">
                        <div>แต้มที่ใช้แลก</div>
                        <div>{{redemption.points_redeemed | number}}</div>
                    </div>
                    <div class="flex space-x-2 text-xs text-gray-500">
                        <div>แลกเมื่อ</div>
                        <div>{{dateFormat(redemption.created.date)}}</div>
                    </div>
                </button>
                }
            </div>
        </div>
        <div class="absolute bottom-0 flex px-4 bg-white pt-2 pb-4 w-full shadow-sm justify-center items-center">
            <div class="flex flex-col gap-4 w-full">
                <div class="self-center">
                    <button class="text-gray-500 underline" (click)="removeDiscount()">ไม่ต้องการใช้ส่วนลด</button>
                </div>
                <div class="grid grid-cols-2 gap-2 pt-2">
                    <button class="btn-secondary" (click)="close()">
                        ยกเลิก
                    </button>
                    <button class="btn-primary" (click)="applyDiscount()">
                        ยืนยัน
                    </button>
                </div>
            </div>
        </div>
        }

        }
    </div>
</div>

@if (processing) {
    <div class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-5 z-50 flex justify-center items-center">
        <span class="loader-processing !w-9 !h-9 !border-8 -mt-10"></span>
    </div>
    }